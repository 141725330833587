import { __assign, __extends } from "tslib";
import * as React from 'react';
import { getPopupContainer } from '@cainiaofe/cn-ui-common';
import { throttle } from 'throttle-debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import $i18n from 'panda-i18n';
import { CnAsyncSelect as Select } from '@/components/cn-async-select';
import { useCreatePluginInstance, HocBaseComponents, } from '@/components/cn-utils';
import request from './request';
import { EmployeeInfo } from './employee-info';
import { transformValue, transformValueToArray, renderEmployeeItem, processDataSource } from './utils';
import { isNil } from '@fusion/lib/util/object';
import classNames from 'classnames';
var EmployeeSelectBase = /** @class */ (function (_super) {
    __extends(EmployeeSelectBase, _super);
    function EmployeeSelectBase(props) {
        var _this = _super.call(this, props) || this;
        /**
         * 根据 props 的值 自动搜索
         * 用于获取到用户工号后，填充 cache 的用途
         * 如果 cache 中已经有了全部的人员则不需要再次搜索
         * @param {String|Array} searchValue 搜索条件
         */
        // eslint-disable-next-line react/sort-comp
        _this.autoSearch = function (searchValue) {
            if (searchValue.length > 0) {
                var searchStr = transformValue(searchValue);
                if (!_this.checkEmployeeCache(searchStr.split(','))) {
                    _this.searchEmployee(searchStr, 'staffId');
                }
            }
        };
        /**
         * 根据搜索条件去搜索人
         * @param {String} searchStr 搜索关键字 工号 111499 或 111499,222444
         */
        _this.searchEmployee = function (searchStr, fromType) {
            var _a, _b, _c, _d;
            var _e = _this.props, service = _e.service, onSearch = _e.onSearch, serviceType = _e.serviceType, _f = _e.host, host = _f === void 0 ? '' : _f, mokelay = _e.mokelay, isOnJob = _e.isOnJob;
            var _isOnJob = isOnJob === 'all' ? null : isOnJob;
            var employeeMap = _this.state.employeeMap;
            if (onSearch) {
                // 当用户自定义 onSearch 的情况下，不执行接口请求
                onSearch(searchStr);
            }
            else if ('dataSource' in _this.props) {
                // 当用户自定义 dataSource 的情况下，不执行接口请求
            }
            else if (service) {
                // 自定义请求能力
                _this.setState({
                    loading: true,
                });
                service(searchStr, fromType)
                    .then(function (res) {
                    var processData = EmployeeSelectBase.processDataSource(res, employeeMap);
                    _this.setState(__assign(__assign({}, processData), { loading: false }));
                })
                    .catch(function () {
                    _this.setState({
                        loading: false,
                    });
                });
            }
            else {
                // 执行内置请求
                if (serviceType === 'cainiao') {
                    if (fromType === 'keywords') {
                        _this.setState({
                            loading: true,
                        });
                        var result = mokelay
                            ? request({
                                url: "".concat(host, "/cmdp/hysmab/fuzzyQueryOnJobEmployee"),
                                method: 'POST',
                                data: {
                                    searchKey: searchStr,
                                    typeList: (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.typeList) === null || _b === void 0 ? void 0 : _b.join(','),
                                    isOnJob: _isOnJob,
                                    locale: $i18n.getLang(),
                                },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: "".concat(host, "/cn-common-proxy/post"),
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/fuzzyQueryOnJobEmployee',
                                    params: {
                                        searchKey: searchStr,
                                        typeList: (_d = (_c = _this.props) === null || _c === void 0 ? void 0 : _c.typeList) === null || _d === void 0 ? void 0 : _d.join(','),
                                        isOnJob: _isOnJob,
                                        locale: $i18n.getLang(),
                                    },
                                },
                            });
                        result
                            .then(function (res) {
                            var processData = EmployeeSelectBase.processDataSource(res.data.data, employeeMap, true);
                            _this.setState(__assign(__assign({}, processData), { loading: false }));
                        })
                            .catch(function () {
                            _this.setState({
                                loading: false,
                            });
                        });
                    }
                    if (fromType === 'staffId' && searchStr) {
                        _this.setState({
                            loading: true,
                        });
                        var result = mokelay
                            ? request({
                                url: "".concat(host, "/cmdp/hysmab/getOnJobEmployeeByEmpNo"),
                                method: 'post',
                                data: { empId: searchStr, locale: $i18n.getLang() },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: "".concat(host, "/cn-common-proxy/post"),
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getOnJobEmployeeByEmpNo',
                                    params: { empId: searchStr, locale: $i18n.getLang() },
                                },
                            });
                        result
                            .then(function (res) {
                            var processData = EmployeeSelectBase.processDataSource(res.data.data, employeeMap);
                            _this.setState({
                                employeeMap: processData.employeeMap,
                                loading: false,
                            });
                        })
                            .catch(function () {
                            _this.setState({
                                loading: false,
                            });
                        });
                    }
                }
                if (serviceType === 'ali') {
                    // TODO: 接入阿里员工数据源
                    // if
                    if (fromType === 'keywords') {
                        _this.setState({
                            loading: true,
                        });
                        var result = mokelay
                            ? request({
                                url: "".concat(host, "/cmdp/hysmab/getAliUserInfoBySearchKey"),
                                method: 'POST',
                                data: { searchKey: searchStr, locale: $i18n.getLang() },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: "".concat(host, "/cn-common-proxy/post"),
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getAliUserInfoBySearchKey',
                                    params: { searchKey: searchStr, locale: $i18n.getLang() },
                                },
                            });
                        result
                            .then(function (res) {
                            var processData = EmployeeSelectBase.processDataSource(res.data.data, employeeMap, true);
                            _this.setState(__assign(__assign({}, processData), { employeeMap: __assign({}, processData.employeeMap), loading: false }), function () { });
                        })
                            .catch(function () {
                            _this.setState({
                                loading: false,
                            });
                        });
                    }
                    if (fromType === 'staffId' && searchStr) {
                        _this.setState({
                            loading: true,
                        });
                        var result = mokelay
                            ? request({
                                url: "".concat(host, "/cmdp/hysmab/getAliUserInfoByEmpNo"),
                                method: 'POST',
                                data: { empId: searchStr, locale: $i18n.getLang() },
                                crossOrigin: true,
                                withCredentials: true,
                                isCommonRequest: true,
                            })
                            : request({
                                url: "".concat(host, "/cn-common-proxy/post"),
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    path: '/cmdp/hysmab/getAliUserInfoByEmpNo',
                                    params: { empId: searchStr, locale: $i18n.getLang() },
                                },
                            });
                        result
                            .then(function (res) {
                            var processData = EmployeeSelectBase.processDataSource(res.data.data, employeeMap);
                            _this.setState(__assign(__assign({}, processData), { employeeMap: __assign({}, processData.employeeMap), loading: false }), function () { });
                        })
                            .catch(function () {
                            _this.setState({
                                loading: false,
                            });
                        });
                    }
                }
            }
        };
        _this.transUsers = function (value) {
            return transformValueToArray(value, _this.props.valuePaddingZero, _this.props.valueCommaJoin);
        };
        _this.handleValueChange = function (newValue) {
            var employeeMap = _this.state.employeeMap;
            var _a = _this.props, onChange = _a.onChange, mode = _a.mode, useDetailValue = _a.useDetailValue, valueCommaJoin = _a.valueCommaJoin, isArrayValue = _a.isArrayValue;
            var newValueArray = _this.transUsers(newValue);
            if (useDetailValue) {
                newValueArray = newValueArray.map(function (newItem) {
                    if (employeeMap[newItem]) {
                        return employeeMap[newItem];
                    }
                    return { workNo: newItem };
                });
            }
            if (mode === 'single') {
                var realValue = newValueArray[0] || null;
                if (isArrayValue === true) {
                    if (!isNil(realValue)) {
                        realValue = [realValue];
                    }
                }
                onChange && onChange(realValue, newValue);
            }
            if (mode !== 'single' && !valueCommaJoin) {
                onChange && onChange(newValueArray, newValue);
            }
            if (mode !== 'single' && valueCommaJoin) {
                onChange && onChange(transformValue(newValueArray), newValue);
            }
            _this.setState({
                selected: _this.transUsers(newValue),
            });
        };
        _this.selectedValueRender = function (employeeStaffId) {
            var _a;
            if (isEmpty(employeeStaffId))
                return;
            var customValueRender = _this.props.customValueRender;
            var employeeMap = _this.state.employeeMap;
            // 可能是个数组
            if (Array.isArray(employeeStaffId)) {
                employeeStaffId = (_a = employeeStaffId[0]) === null || _a === void 0 ? void 0 : _a.value;
            }
            else {
                employeeStaffId = employeeStaffId.value
                    ? employeeStaffId.value
                    : employeeStaffId;
            }
            var selectedEmployee = employeeMap[employeeStaffId];
            if (customValueRender) {
                return customValueRender(selectedEmployee || {});
            }
            // 中文环境下优先取花名回显，非中文环境下优先取姓名
            var displayName = $i18n.getLang() === 'zh-CN' ?
                (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.nickName) || (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.name)
                : (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.name) || (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.nickName);
            return (React.createElement("span", { key: employeeStaffId, "data-i18n-ignore": true }, displayName || employeeStaffId || ''));
        };
        _this.handleSearchChange = throttle(1200, function (searchStr) {
            _this.searchEmployee(searchStr, 'keywords');
        });
        _this.handleVisibleChange = function (visible) {
            _this.setState({
                visible: visible,
            });
        };
        _this.renderReadStatus = function () {
            var _a;
            var _b = _this.state, employeeMap = _b.employeeMap, selected = _b.selected;
            var readOnlyRender = _this.props.readOnlyRender;
            var dataSource = [];
            selected.forEach(function (workid) {
                if (employeeMap[workid]) {
                    dataSource.push(employeeMap[workid]);
                }
                else {
                    dataSource.push({
                        name: workid,
                        workNo: workid,
                    });
                }
            });
            if (readOnlyRender) {
                return readOnlyRender(dataSource);
            }
            // 根据规范，当值为空的时候显示 - -
            if (!((_a = _this.props.value) === null || _a === void 0 ? void 0 : _a.length) && dataSource.length === 0) {
                return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-employee-select-readonly') }, "- -"));
            }
            return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-employee-select-readonly') }, dataSource.map(function (empl, i) { return (React.createElement(React.Fragment, { key: empl.workNo },
                React.createElement(EmployeeInfo, { dataSource: empl }),
                i !== dataSource.length - 1 && '、')); })));
        };
        var st = {
            selected: _this.transUsers(props.value || props.defaultValue),
            employeeMap: {},
            loading: false,
            dataSource: [],
            visible: false,
            urlPrefix: props.remoteService === 'local' ? '/cn-mokelay' : '',
        };
        if (props.dataSource) {
            var processData = EmployeeSelectBase.processDataSource(props.dataSource, {});
            st.dataSource = processData.dataSource;
            st.employeeMap = processData.employeeMap;
        }
        _this.state = st;
        return _this;
    }
    EmployeeSelectBase.getDerivedStateFromProps = function (nextProps, prevState) {
        var changeState = {};
        if ('value' in nextProps) {
            var nextSelected = transformValueToArray(nextProps.value, nextProps.valuePaddingZero, nextProps.valueCommaJoin);
            if (!isEqual(nextSelected, prevState.selected)) {
                changeState.selected = nextSelected;
            }
            if (nextProps.useDetailValue) {
                var value = Array.isArray(nextProps.value)
                    ? nextProps.value
                    : [nextProps.value];
                var employeeMap = value.reduce(function (pre, cur) {
                    var _a;
                    return (__assign(__assign({}, pre), (_a = {}, _a[cur === null || cur === void 0 ? void 0 : cur.workNo] = cur, _a)));
                }, {});
                changeState.employeeMap = __assign(__assign({}, ((prevState === null || prevState === void 0 ? void 0 : prevState.employeeMap) || {})), employeeMap);
            }
        }
        if ('dataSource' in nextProps) {
            var processData = EmployeeSelectBase.processDataSource(nextProps.dataSource, prevState.employeeMap);
            changeState.employeeMap = processData.employeeMap;
            changeState.dataSource = processData.dataSource;
        }
        return changeState;
    };
    EmployeeSelectBase.prototype.componentDidMount = function () {
        // 那么先自动请求一波
        this.searchEmployee(transformValue(this.state.selected), 'staffId');
    };
    EmployeeSelectBase.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (!isEqual(prevState.selected, this.state.selected)) {
            // 当 selected 更新后，更新 employeeMap 详细值
            this.autoSearch(this.state.selected);
        }
    };
    /**
     * 检查工号数组是否都已经在 cache 中存在，如果都存在返回 true，反之返回 false
     */
    EmployeeSelectBase.prototype.checkEmployeeCache = function (staffIdArray) {
        var employeeMap = this.state.employeeMap;
        var existInCache = staffIdArray.find(function (item) {
            if (!employeeMap[item]) {
                return true;
            }
            return false;
        });
        // 如果没找到则 notExistInCache === undefined
        return !existInCache;
    };
    EmployeeSelectBase.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.state, dataSource = _b.dataSource, selected = _b.selected, loading = _b.loading, visible = _b.visible;
        var _c = this.props, mode = _c.mode, _d = _c.style, style = _d === void 0 ? {} : _d, className = _c.className, readOnly = _c.readOnly, itemPropsRef = _c.itemPropsRef, popupContainer = _c.popupContainer;
        if (itemPropsRef) {
            itemPropsRef.current = {
                renderSelected: function (key, value, itemLabel) {
                    if (Array.isArray(value)) {
                        return (React.createElement("span", null,
                            itemLabel,
                            ":",
                            value.map(function (valueItem) {
                                return React.createElement("span", null,
                                    " ",
                                    _this.selectedValueRender(valueItem));
                            })));
                    }
                    return (React.createElement("span", null,
                        itemLabel,
                        ":",
                        _this.selectedValueRender(value)));
                },
            };
        }
        var selectStyle = __assign({}, style);
        var classes = classNames((_a = {
                'cn-ui-employee-select': true
            },
            _a[className] = !!className,
            _a));
        // let selectState;
        // if (loading) selectState = 'loading';
        var selectState = loading ? 'loading' : undefined;
        if (readOnly)
            return this.renderReadStatus();
        var selectProps = omit(this.props, [
            'style',
            'className',
            'mode',
            'readOnly',
            'readOnlyRender',
            'useDetailValue',
            'value',
            'onChange',
            'locale',
            'customValueRender',
            'service',
            'onSearch',
            'dataSource',
        ]);
        return (React.createElement(Select, __assign({ "data-name": "CnEmployeeSelect", style: selectStyle, className: classes, mode: mode, value: selected, valueRender: this.selectedValueRender, dataSource: dataSource, visible: visible, onVisibleChange: this.handleVisibleChange, itemRender: function (item) { return renderEmployeeItem(item); }, onChange: this.handleValueChange, onSearch: this.handleSearchChange, showSearch: true, hasClear: true, filterLocal: false, popupClassName: "cn-ui-employee-select-popup", placeholder: $i18n.get({
                id: 'PleaseEnterWorkNumberOrFlower_129025031',
                dm: '请输入工号或花名',
                ns: 'CnEmployeeSelect',
            }), notFoundContent: $i18n.get({
                id: 'RelevantPersonnelNotFound',
                dm: '未找到相关人员',
                ns: 'CnEmployeeSelect',
            }), state: selectState, popupContainer: getPopupContainer(popupContainer) }, selectProps)));
    };
    EmployeeSelectBase.displayName = 'EmployeeSelect';
    EmployeeSelectBase.defaultProps = {
        mode: 'single',
        mokelay: true,
        remoteService: 'local',
        useDetailValue: false,
        useInternalService: false,
        valuePaddingZero: true,
        isOnJob: true,
        typeList: ['REGULAR', 'OUTSOURCING'],
        isArrayValue: false,
    };
    /**
     * 将 dataSource 处理成 employeeMap (缓存)
     */
    EmployeeSelectBase.processDataSource = function (dataSource, employeeMap, updateEmployeeMap) {
        return processDataSource(dataSource, employeeMap, updateEmployeeMap);
    };
    return EmployeeSelectBase;
}(React.Component));
var componentNameForPlugin = 'CnEmployeeSelect';
export function EmployeeSelect(props) {
    var _a;
    var pluginIns = useCreatePluginInstance(componentNameForPlugin, props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if ((plugins === null || plugins === void 0 ? void 0 : plugins.length) === 0) {
        return React.createElement(EmployeeSelectBase, __assign({}, props));
    }
    return HocBaseComponents(EmployeeSelectBase, {
        props: props,
        plugins: plugins,
    });
}
EmployeeSelect.displayName = 'EmployeeSelect';
