import { __assign, __rest } from "tslib";
import './scss/index.scss';
import * as React from 'react';
import { Pagination as NextPagination } from '@fusion/lib';
import { useNextLocale } from '../cn-utils';
import classNames from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnPagination = function (props) {
    var _a;
    var className = props.className, align = props.align, currentPage = props.currentPage, totalCount = props.totalCount, restProps = __rest(props, ["className", "align", "currentPage", "totalCount"]);
    var paginationLocale = useNextLocale('Pagination');
    var nextPaginationProps = __assign(__assign(__assign({}, restProps), (currentPage !== undefined && { current: currentPage })), (totalCount !== undefined && { total: totalCount }));
    return withNativeProps(props, React.createElement(NextPagination, __assign({ "data-name": "CnPagination" }, nextPaginationProps, { locale: paginationLocale, className: classNames(className, (_a = {},
            _a["cn-pagination--align-".concat(align)] = align,
            _a)) })));
};
CnPagination.displayName = 'CnPagination';
/**
 * @deprecated  请使用 CnPagination 替换
 */
export var Pagination = NextPagination;
