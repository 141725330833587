import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { formilyReact } from '@/form/formily';
import { useFormLayout } from '@/form/cn-form-layout';
import { useArray, useIndex } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var useField = formilyReact.useField;
export var Remove = React.forwardRef(function (props, ref) {
    var _a;
    var _b;
    var onClick = props.onClick, propsIndex = props.index, propsDisabled = props.disabled, restProps = __rest(props, ["onClick", "index", "disabled"]);
    var index = useIndex(propsIndex);
    var self = useField();
    var array = useArray();
    var disabled = propsDisabled || (self === null || self === void 0 ? void 0 : self.disabled);
    var reRender = useFormLayout().reRender;
    if (!array)
        return null;
    if (((_b = array.field) === null || _b === void 0 ? void 0 : _b.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, __assign({ text: true, ref: ref, type: "primary", warning: true }, restProps, { disabled: disabled, onClick: function (e) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (disabled)
                return;
            e.stopPropagation();
            (_b = (_a = array.field) === null || _a === void 0 ? void 0 : _a.remove) === null || _b === void 0 ? void 0 : _b.call(_a, index);
            (_c = array.field) === null || _c === void 0 ? void 0 : _c.form.setValuesIn((_e = (_d = array.field) === null || _d === void 0 ? void 0 : _d.path) === null || _e === void 0 ? void 0 : _e.segments, cloneDeep((_f = array.field) === null || _f === void 0 ? void 0 : _f.value));
            (_h = (_g = array.props) === null || _g === void 0 ? void 0 : _g.onRemove) === null || _h === void 0 ? void 0 : _h.call(_g, index);
            (_j = props.onClick) === null || _j === void 0 ? void 0 : _j.call(props, e);
            if (reRender) {
                reRender();
            }
        } }), props.children || (React.createElement(CnIcon, { type: "icon-delete", className: classNames("".concat(cssPrefix, "-remove"), (_a = {},
            _a["".concat(cssPrefix, "-remove-disabled")] = disabled,
            _a)) }))));
});
Remove.displayName = 'Remove';
