import { __assign, __rest } from "tslib";
import './scss/index.scss';
import './scss/calendar.scss';
import * as React from 'react';
import NextDatePicker2 from './date-picker';
import compact from 'lodash/compact';
import { CnReadOnly } from '@/components/cn-read-only';
import { useNextLocale, useValueState, } from '@/components/cn-utils';
import classNames from 'classnames';
import { formatValue, getValueFromDayjs } from './util';
import { CnPageScrollDomSelector, useLocalization, withNativeProps } from '@cainiaofe/cn-ui-common';
import { date2StringByModeShowTime } from './utils';
import { defaultEndOfDay } from './constants';
var MonthPicker = NextDatePicker2.MonthPicker, YearPicker = NextDatePicker2.YearPicker, WeekPicker = NextDatePicker2.WeekPicker, QuarterPicker = NextDatePicker2.QuarterPicker, RangePicker = NextDatePicker2.RangePicker;
var renderPreview = function (value, readOnlyProps) {
    return (React.createElement(CnReadOnly, __assign({ value: value, valueSeparator: " - ", type: "string" }, readOnlyProps)));
};
function nextCompMap(Comp, displayName) {
    var Component = React.forwardRef(function (props, ref) {
        var _a;
        var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, outputFormat = props.outputFormat, className = props.className, showTime = props.showTime, endOfDay = props.endOfDay, popupContainer = props.popupContainer, state = props.state, otherProps = __rest(props, ["readOnly", "readOnlyProps", "outputFormat", "className", "showTime", "endOfDay", "popupContainer", "state"]);
        var locale = useNextLocale('DatePicker');
        var adapterLocale = useLocalization('dateAdapterLocale', props.adapterLocale);
        var _b = useValueState(props, undefined), value = _b[0], setValue = _b[1], isControlled = _b[2];
        var datePickerRef = React.useRef();
        React.useImperativeHandle(ref, function () { return datePickerRef.current; }, [
            datePickerRef,
        ]);
        var onChange = function (newValue, strVal) {
            if (!isControlled) {
                setValue(newValue);
            }
            var v = getValueFromDayjs(newValue, !showTime, endOfDay);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onChange && props.onChange(v, strVal);
        };
        var onOk = function (newValue, strVal) {
            if (!isControlled) {
                setValue(newValue);
            }
            var v = getValueFromDayjs(newValue, !showTime, endOfDay);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onOk && props.onOk(v, strVal);
        };
        var disabledDate = otherProps.disabledDate;
        if (disabledDate) {
            otherProps.disabledDate = function (date, mode) {
                var _a, _b, _c;
                return disabledDate(date, mode, (_c = (_b = (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.getInstance()) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.value);
            };
        }
        // showTime 时的兼容逻辑，不必写 timePanelProps format
        var timePanelProps = props.timePanelProps;
        if (showTime && props.format && typeof props.format === 'string') {
            var timeFormat = props.format.trim().split(/\s+/)[1];
            if (timeFormat) {
                timePanelProps = __assign({}, timePanelProps) || {};
                timePanelProps.format = timeFormat;
            }
        }
        return withNativeProps(props, React.createElement(Comp, __assign({ "data-name": "CnDatePickerPro", className: classNames(className, 'cn-ui-date-picker-pro', (_a = {},
                _a["cn-ui-date-picker-pro-".concat(state)] = state,
                _a)), ref: datePickerRef, renderPreview: function (renderValue) {
                var showValue;
                if (Array.isArray(renderValue)) {
                    showValue = compact(renderValue);
                    showValue = showValue.map(function (i) {
                        return (i &&
                            date2StringByModeShowTime(i, props === null || props === void 0 ? void 0 : props.mode, showTime, props.format, displayName)) ||
                            '';
                    });
                }
                else {
                    showValue =
                        (renderValue &&
                            date2StringByModeShowTime(renderValue, props === null || props === void 0 ? void 0 : props.mode, showTime, props.format, displayName)) ||
                            '';
                }
                return renderPreview(showValue, readOnlyProps);
            }, isPreview: props.readOnly, showTime: showTime, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : (function (e) {
                return (e === null || e === void 0 ? void 0 : e.closest(CnPageScrollDomSelector)) ||
                    (e === null || e === void 0 ? void 0 : e.closest('.cn-next-dialog-wrapper')) ||
                    document.body;
            }), timePanelProps: timePanelProps, state: state, locale: locale }, otherProps, { value: value, onChange: onChange, onOk: onOk, adapterLocale: adapterLocale })));
    });
    Component.displayName = displayName;
    return Component;
}
/**
 * @deprecated
 * 使用 CnDatePickerPro
 */
export var CnDatePicker2 = nextCompMap(NextDatePicker2, 'CnDatePicker2');
/**
 * @deprecated
 * 使用 CnMonthPickerPro
 */
export var CnMonthPicker2 = nextCompMap(MonthPicker, 'CnMonthPicker2');
/**
 * @deprecated
 * 使用 CnYearPickerPro
 */
export var CnYearPicker2 = nextCompMap(YearPicker, 'CnYearPicker2');
/**
 * @deprecated
 * 使用 CnWeekPickerPro
 */
export var CnWeekPicker2 = nextCompMap(WeekPicker, 'CnWeekPicker2');
/**
 * @deprecated
 * 使用 CnQuarterPickerPro
 */
export var CnQuarterPicker2 = nextCompMap(QuarterPicker, 'CnQuarterPicker2');
/**
 * @deprecated
 * 使用 CnRangeDatePickerPro
 */
export var CnRangeDatePicker2 = nextCompMap(RangePicker, 'CnRangeDatePicker2');
CnRangeDatePicker2.defaultProps = {
    endOfDay: defaultEndOfDay,
};
export var CnDatePickerPro = CnDatePicker2;
export var CnMonthPickerPro = CnMonthPicker2;
export var CnYearPickerPro = CnYearPicker2;
export var CnWeekPickerPro = CnWeekPicker2;
export var CnQuarterPickerPro = CnQuarterPicker2;
export var CnRangeDatePickerPro = CnRangeDatePicker2;
