import { numberFormat } from '@cainiaofe/cn-ui-common';
import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
export default (function (value, adapterLocale) {
    // 如果未空返回 - -
    if (isNil(value) || value === '') {
        return '- -';
    }
    // 验证值的类型是否为字符串或数字
    var valueTypeVerify = typeof value === 'string' || typeof value === 'number';
    // 如果值的类型不是字符串或数字，或者值不能转换为数字，直接返回值
    if (!valueTypeVerify || isNaN(Number(value))) {
        return value;
    }
    // 将值转换为 BigNumber 类型
    var valueNumber = new BigNumber(value);
    // 验证转换后的值是否为数字类型
    var valueIsLikeNumber = typeof valueNumber.toNumber() === 'number';
    // 判断是否可以进行微米级别的处理
    var canMicrometer = valueTypeVerify && valueIsLikeNumber;
    // 如果可以进行微米级别的处理&开启了国际化适配，返回格式化后的值
    if (canMicrometer && adapterLocale) {
        return numberFormat(value, adapterLocale);
    }
    // 如果可以进行微米级别的处理，返回格式化后的值
    if (canMicrometer) {
        return valueNumber.toFormat();
    }
    // 否则，直接返回值
    return value;
});
