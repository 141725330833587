import { getCurrentLang } from 'panda-i18n';
function i18nList(list, currentLang) {
    var _a;
    for (var _i = 0, _b = list.filter(Boolean); _i < _b.length; _i++) {
        var item = _b[_i];
        if (item.i18n && item.label) {
            item.label = item.i18n[currentLang] || item.label;
            if ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) {
                i18nList(item.children, currentLang);
            }
        }
    }
}
/**
 * dataSource 国际化处理
 * 该方法会根据当前语言更新数据源的标签，会修改原始数据源，不会返回新的引用对象，同时返回原始数据源
 */
export function dataSourceI18n(dataSource) {
    var currentLang = getCurrentLang();
    i18nList(dataSource || [], currentLang);
    return dataSource;
}
