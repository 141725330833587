var _a;
import { __assign } from "tslib";
import './view/cn-box.scss';
import * as React from 'react';
import NextBox from '@fusion/lib/box';
import $i18n from 'panda-i18n';
var isRTL = (_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n);
export var CnBox = function (props) {
    return React.createElement(NextBox, __assign({ "data-name": "CnBox" }, props, { rtl: isRTL }));
};
export var Box = CnBox;
CnBox.displayName = 'CnBox';
