import * as cnUtils from '@cainiaofe/cn-utils';
export var STANDARD_DECIMAL_SEPARATOR = '.';
/**
 * 格式化数字
 * @param num - 数字
 * @param adapterLocale - 本地化适配标
 * @returns -
 */
export var numberFormat = function (num, adapterLocale) {
    if (!num) {
        return num;
    }
    if (!adapterLocale || !(cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.numberFormat)) {
        return num;
    }
    return cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.numberFormat(num, adapterLocale, {
        maximumFractionDigits: 20,
    });
};
/**
 * 获取小数点
 * @param adapterLocale - 本地化适配标
 * @returns -
 */
export var getDecimalSeparator = function (adapterLocale) {
    if (!adapterLocale || !(cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDecimalSeparator)) {
        return STANDARD_DECIMAL_SEPARATOR;
    }
    return cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDecimalSeparator(adapterLocale);
};
/**
 * 将标准格式转本地格式
 * @param numText - 数字
 * @param adapterLocale - 本地化适配标
 * @returns -
 */
export var convertStandardToLocale = function (numText, adapterLocale) {
    var _a, _b;
    if (!adapterLocale || !numText) {
        return numText;
    }
    return (_b = (_a = "".concat(numText)).replace) === null || _b === void 0 ? void 0 : _b.call(_a, STANDARD_DECIMAL_SEPARATOR, getDecimalSeparator(adapterLocale));
};
/**
 * 将本地格式转标准格式
 * @param numText - 数字
 * @param adapterLocale - 本地适配标
 * @returns -
 */
export var convertLocaleToStandard = function (numText, adapterLocale) {
    var _a, _b;
    if (!adapterLocale || !numText) {
        return numText;
    }
    return (_b = (_a = "".concat(numText)).replace) === null || _b === void 0 ? void 0 : _b.call(_a, getDecimalSeparator(adapterLocale), STANDARD_DECIMAL_SEPARATOR);
};
