import { __awaiter, __generator } from "tslib";
import './index.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnMenuButton, CnMenuButtonItem } from '@/components/cn-menu-button';
import keyBy from 'lodash/keyBy';
import { langList as allLangList, pandaEvent, useI18n, } from 'panda-i18n';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var allLangMap = keyBy(allLangList, 'value');
export var CnI18nSelect = function (props) {
    var _a;
    var hasIcon = props.hasIcon, _b = props.langList, langList = _b === void 0 ? ['zh-CN', 'en-US'] : _b, lang = props.lang, _c = props.triggerType, triggerType = _c === void 0 ? 'hover' : _c, beforeChange = props.beforeChange, onChange = props.onChange;
    // get panda-i18n instance
    var $i18n = useI18n();
    // current lang state
    var _d = useState(lang || $i18n.getLang()), currentLang = _d[0], setCurrentLang = _d[1];
    // user controlled lang
    useEffect(function () {
        lang && setCurrentLang(lang);
    }, [lang]);
    // get user usable lang list
    var usedLangList = langList === null || langList === void 0 ? void 0 : langList.map(function (_lang) {
        return allLangMap[_lang] || {
            value: _lang,
            label: _lang,
        };
    });
    // lister lang changed then change the current lang
    useEffect(function () {
        var changeLangHandler = pandaEvent.on('CHANGE_LANG', function (_lang) {
            if (!lang) {
                setCurrentLang(_lang);
            }
        });
        return function () { return pandaEvent.off(changeLangHandler); };
    }, []);
    return withNativeProps(props, React.createElement(CnMenuButton, { popupTriggerType: triggerType, popupProps: {
            offset: [0, 20],
            rtl: $i18n.isRTL(),
        }, type: "normal", size: "small", label: withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-i18n-select-label'), "data-testid": "CnI18nSelect" },
            hasIcon && React.createElement(CnIcon, { size: "small", type: "icon-international1" }),
            React.createElement("span", { "data-i18n-ignore": true }, (_a = allLangMap[currentLang]) === null || _a === void 0 ? void 0 : _a.label))) }, usedLangList === null || usedLangList === void 0 ? void 0 : usedLangList.map(function (langObj) {
        return (React.createElement(CnMenuButtonItem, { className: classNames({
                'cn-next-selected': langObj.value === currentLang,
            }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var result, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(beforeChange && typeof beforeChange === 'function')) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, beforeChange(langObj.value)];
                        case 2:
                            result = _a.sent();
                            if (result === false) {
                                return [2 /*return*/];
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            err_1 = _a.sent();
                            return [2 /*return*/];
                        case 4:
                            $i18n.setLang(langObj.value);
                            if (onChange) {
                                onChange(langObj.value);
                            }
                            else {
                                location.reload();
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, key: langObj.value },
            React.createElement("span", { "data-i18n-ignore": true }, langObj.label)));
    })));
    //
    // return (
    //   <Dropdown
    //     trigger={
    //       <span className="cn-i18n-select" aria-haspopup="true">
    //         {allLangMap[currentLang]?.alias ||
    //           currentLang.substring(0, 1).toUpperCase() +
    //             currentLang.substring(1, 2).toLowerCase()}
    //       </span>
    //     }
    //     offset={[0, 20]}
    //     triggerType={triggerType}
    //   >
    //     <Menu className="cn-i18n-select-menu" selectedKeys={$i18n.getLang()}>
    //       {usedLangList.map((langObj) => {
    //         return (
    //           <Menu.Item
    //             onClick={async () => {
    //               if (beforeChange && typeof beforeChange === 'function') {
    //                 try {
    //                   const result = await beforeChange(langObj.value);
    //                   if (result === false) {
    //                     return
    //                   }
    //                 } catch (err) {
    //                   return
    //                 }
    //               }
    //               $i18n.setLang(langObj.value);
    //               if (onChange) {
    //                 onChange(langObj.value);
    //               } else {
    //                 location.reload();
    //               }
    //             }}
    //             key={langObj.value}
    //           >
    //             {langObj.label}
    //           </Menu.Item>
    //         );
    //       })}
    //     </Menu>
    //   </Dropdown>
    // );
};
CnI18nSelect.defaultProps = {
    hasIcon: true,
};
