import { __assign, __rest } from "tslib";
import './view/cn-collapse.scss';
import * as React from 'react';
import { Collapse as UiCollapse } from '@fusion/lib';
import classNames from 'classnames';
import { withNativeProps } from '../cn-utils';
export var CnCollapse = React.forwardRef(function (props, ref) {
    var className = props.className, otherProps = __rest(props, ["className"]);
    return withNativeProps(props, React.createElement(UiCollapse, __assign({ className: classNames(className, 'cn-ui-collapse'), "data-name": "CnCollapse", ref: ref }, otherProps)));
});
CnCollapse.displayName = 'CnCollapse';
export var CnCollapsePanel = UiCollapse.Panel;
CnCollapsePanel.displayName = 'CnCollapsePanel';
/**
 * @deprecated
 * 推荐使用 CnCollapsePanel
 */
CnCollapse.Panel = CnCollapsePanel;
