import $i18n from 'panda-i18n'; /* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
var ImageThumb = function (props) {
    var thumb = props.thumb, errorUrl = props.errorUrl, chooseUrl = props.chooseUrl, chooseIndex = props.chooseIndex, onError = props.onError, onClickThumb = props.onClickThumb, customRenderThumbSlot = props.customRenderThumbSlot;
    // 最大滚动距离
    var _a = useState(0), maxScrollDistance = _a[0], setMaxScrollDistance = _a[1];
    // 滚动容器 ref
    var thumbContainerRef = useRef(null);
    // 缩略图元素列表
    var thumbElListRef = useRef(null);
    useEffect(function () {
        thumbElListRef.current = document.getElementsByClassName('cn-ui-image-viewer-thumb-item');
        setMaxScrollDistance(thumbContainerRef.current.scrollWidth -
            thumbContainerRef.current.clientWidth);
    }, [thumb]);
    var renderItem = function (url, index) {
        if (url) {
            return (React.createElement("div", { className: "cn-ui-image-viewer-thumb-img-wrapper" },
                React.createElement("img", { src: url, alt: "", width: "100%", height: "100%", onError: function (e) {
                        e.target.src = errorUrl;
                        onError(e);
                    } }), customRenderThumbSlot === null || customRenderThumbSlot === void 0 ? void 0 :
                customRenderThumbSlot(url, index)));
        }
        return (React.createElement("div", { className: 'cn-ui-image-viewer-thumb-no-image-display', style: { width: '100%', height: '100%' } }, $i18n.get({ id: 'NoPicture', dm: '无图片', ns: 'CnImageViewer' })));
    };
    useEffect(function () {
        var _a;
        // 自动滚动到选中的元素
        var curItem = (_a = thumbElListRef.current) === null || _a === void 0 ? void 0 : _a[chooseIndex];
        curItem.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
        });
    }, [chooseIndex]);
    return (React.createElement("div", { className: "cn-ui-image-viewer-thumb-wrapper", onClick: function (e) { return e.stopPropagation(); } },
        Boolean(maxScrollDistance) && React.createElement("div", { className: "cn-ui-image-viewer-blur-left" }),
        React.createElement("div", { ref: thumbContainerRef, className: "cn-ui-image-viewer-thumb-pop-container" },
            React.createElement("div", { className: "cn-ui-image-viewer-thumb-list" }, Array.isArray(thumb)
                ? thumb.map(function (item, index) {
                    return (React.createElement("div", { className: classNames('cn-ui-image-viewer-thumb-item', {
                            'cn-ui-image-viewer-thumb-item--select': (chooseUrl !== null && chooseUrl !== void 0 ? chooseUrl : '') === item && chooseIndex === index,
                        }), onClick: function (e) {
                            onClickThumb && onClickThumb('thumb', e, item, index);
                        }, key: index }, renderItem(item, index)));
                })
                : renderItem(thumb, 0))),
        Boolean(maxScrollDistance) && React.createElement("div", { className: "cn-ui-image-viewer-blur-right" })));
};
export default ImageThumb;
