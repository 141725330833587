import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { formilyReact } from '@/form/formily';
import { useFormLayout } from '@/form/cn-form-layout';
import { useArray, useIndex } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var useField = formilyReact.useField;
export var MoveUp = React.forwardRef(function (props, ref) {
    var _a;
    var _b;
    var onClick = props.onClick, propsIndex = props.index, propsDisabled = props.disabled, restProps = __rest(props, ["onClick", "index", "disabled"]);
    var index = useIndex(propsIndex);
    var self = useField();
    var array = useArray();
    var disabled = propsDisabled || (self === null || self === void 0 ? void 0 : self.disabled) || index === 0;
    var reRender = useFormLayout().reRender;
    if (!array)
        return null;
    if (((_b = array.field) === null || _b === void 0 ? void 0 : _b.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, __assign({ type: "primary", text: true }, restProps, { disabled: disabled, ref: ref, onClick: function (e) {
            var _a, _b, _c, _d;
            if (disabled)
                return;
            e.stopPropagation();
            (_b = (_a = array.field) === null || _a === void 0 ? void 0 : _a.moveUp) === null || _b === void 0 ? void 0 : _b.call(_a, index);
            (_d = (_c = array.props) === null || _c === void 0 ? void 0 : _c.onMoveUp) === null || _d === void 0 ? void 0 : _d.call(_c, index);
            onClick === null || onClick === void 0 ? void 0 : onClick(e);
            if (reRender) {
                reRender();
            }
        } }), props.children || (React.createElement(CnIcon, { type: "icon-arrow-up", className: classNames("".concat(cssPrefix, "-move-up"), (_a = {},
            _a["".concat(cssPrefix, "-move-up-disabled")] = disabled,
            _a)) }))));
});
MoveUp.displayName = 'MoveUp';
