import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import merge from 'lodash/merge';
import getDefaultFormat from './cn-table-cell-format';
var cacheFormat = {};
var defaultRemoteTransform = function (res) {
    var _a;
    return (_a = res === null || res === void 0 ? void 0 : res.data) !== null && _a !== void 0 ? _a : res;
};
/**
 * 全局常量表格配置
 */
export var globalConfig = {
    request: null,
    responseTransform: defaultRemoteTransform,
    defaultParamsTransform: null,
    pageSizeList: [10, 20, 50],
    sizeList: function () { return [
        {
            label: $i18n.get({ id: 'CompactTable', dm: '紧凑表格', ns: 'CnTable' }),
            value: 'small',
        },
        {
            label: $i18n.get({ id: 'NormalForm', dm: '正常表格', ns: 'CnTable' }),
            value: 'medium',
        },
        {
            label: $i18n.get({ id: 'LooseForm', dm: '宽松表格', ns: 'CnTable' }),
            value: 'large',
        },
    ]; },
    format: {},
    defaultPageSize: 10,
};
export function getCellFormat(format, context, adapterLocale) {
    return __assign(__assign(__assign({}, getDefaultFormat(context, adapterLocale)), cacheFormat), format);
}
export function registerFormat(format) {
    cacheFormat = merge(cacheFormat, format);
}
export function config(props) {
    cacheFormat = Object.assign({}, cacheFormat, props === null || props === void 0 ? void 0 : props.format);
    globalConfig = Object.assign(globalConfig, props);
}
