import { __assign } from "tslib";
import React from 'react';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import User from './user';
import { HeaderMenu } from './header-menu';
import { FullHeaderMenu } from './full-header-menu';
import DynamicSlot from './dynamic-slot';
import { LaunchpadMenu } from './launchpad-menu';
import './index.scss';
export var Header = function (_a) {
    var logoStyle = _a.logoStyle, onLogoClick = _a.onLogoClick, logoSrc = _a.logoSrc, launchpadMenu = _a.launchpadMenu, launchpadTriggerType = _a.launchpadTriggerType, onLaunchpadMenuClick = _a.onLaunchpadMenuClick, onLaunchpadMenuDelete = _a.onLaunchpadMenuDelete, launchpadPopupClassName = _a.launchpadPopupClassName, launchpadSlot = _a.launchpadSlot, launchpadSelectedKey = _a.launchpadSelectedKey, onLaunchpadSelect = _a.onLaunchpadSelect, defaultLaunchpadSelectedKey = _a.defaultLaunchpadSelectedKey, menu = _a.menu, menuType = _a.menuType, menuOnSelect = _a.menuOnSelect, hideExternalIcon = _a.hideExternalIcon, onExternalSelect = _a.onExternalSelect, menuSelectedKey = _a.menuSelectedKey, searchSlot = _a.searchSlot, widgetSlot = _a.widgetSlot, userSlot = _a.userSlot, user = _a.user, triggerType = _a.triggerType, fullMenuItemIconRender = _a.fullMenuItemIconRender, menuItemIconRender = _a.menuItemIconRender;
    return (React.createElement("header", { className: "cn-ui-shell-header" },
        React.createElement("img", { className: "logo", src: logoSrc, style: logoStyle, onClick: onLogoClick, alt: "logo" }),
        React.createElement(LaunchpadMenu, __assign({ dataSource: launchpadMenu, triggerType: launchpadTriggerType, onClick: onLaunchpadMenuClick, onDelete: onLaunchpadMenuDelete, popupClassName: launchpadPopupClassName }, pickBy({
            selectedKeys: launchpadSelectedKey,
            onSelect: onLaunchpadSelect,
            defaultSelectedKeys: defaultLaunchpadSelectedKey,
        }, function (p) { return !isNil(p); }))),
        launchpadSlot ? (React.createElement("div", { className: "launchpad-slot" }, launchpadSlot)) : null,
        React.createElement("div", { className: "header-menu" },
            Array.isArray(menu) && menu.length > 0 && menuType === 'single' && (React.createElement(HeaderMenu, { menu: menu, onSelect: menuOnSelect, selectedKeys: menuSelectedKey, triggerType: triggerType, menuItemIconRender: menuItemIconRender })),
            Array.isArray(menu) && menu.length > 0 && menuType === 'full' && (React.createElement(FullHeaderMenu, { menu: menu, onSelect: menuOnSelect, selectedKeys: menuSelectedKey, hideExternalIcon: hideExternalIcon, onExternalSelect: onExternalSelect, fullMenuItemIconRender: fullMenuItemIconRender || menuItemIconRender }))),
        React.createElement(DynamicSlot, null),
        searchSlot ? (React.createElement("div", { className: "cn-ui-shell-header-search-slot" }, searchSlot)) : null,
        widgetSlot ? (React.createElement("div", { className: "cn-ui-shell-header-widget-slot" }, widgetSlot)) : null,
        React.createElement(User, { userSlot: userSlot, user: user })));
};
Header.defaultProps = {
    launchpadTriggerType: 'click',
    triggerType: 'hover',
    menuType: 'single',
};
