import { __assign } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import { Balloon } from '@fusion/lib';
import $i18n from 'panda-i18n';
export var CnTooltip = function (props) {
    var _a;
    return (React.createElement(Balloon.Tooltip, __assign({ "data-name": "CnTooltip" }, props, { className: classnames('cn-balloon-tooltip-style', props.className), rtl: (_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n) }), props.children));
};
CnTooltip.displayName = 'CnTooltip';
