import { __assign, __awaiter, __generator, __rest } from "tslib";
/* eslint-disable */
import * as React from 'react';
import { useSize, usePagination } from 'ahooks';
import { ResponsiveGrid } from '@/components/fusion';
import { CnGrid } from '@/components/cn-grid';
import { CnPagination } from '@/components/cn-pagination';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import assign from 'lodash/assign';
import { packRequest as request } from '@/components/cn-utils/old-request';
import { responsiveSize, sizeColumns } from './const';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var Cell = ResponsiveGrid.Cell;
export var basicsPlugin = {
    useHandleRequestService: function (props, context) {
        var _a = props.requestConfig, requestConfig = _a === void 0 ? {} : _a, pagging = props.pagging;
        var requestService = React.useMemo(function () {
            function takeRequestService(_requestConfig) {
                var _this = this;
                var service = _requestConfig.service, _a = _requestConfig.method, method = _a === void 0 ? 'get' : _a, _b = _requestConfig.formatParam, formatParam = _b === void 0 ? function (params) { return params; } : _b, paramsProps = _requestConfig.params, _c = _requestConfig.formatResult, formatResult = _c === void 0 ? function (res) {
                    var _a, _b, _c;
                    var result = {
                        list: [],
                        total: 0,
                        test: '',
                    };
                    if (res.success) {
                        var list = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.list;
                        var total = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.total;
                        var activeKey = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.activeKey;
                        if (Array.isArray(list)) {
                            result.list = list;
                        }
                        if (!isNil(total)) {
                            result.total = total;
                        }
                        if (!isNil(activeKey)) {
                            result.activeKey = activeKey;
                        }
                    }
                    return result;
                } : _c, url = _requestConfig.url;
                if (isFunction(service)) {
                    return function (searchParams) { return __awaiter(_this, void 0, void 0, function () {
                        var paginationParams;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            paginationParams = pagging
                                ? searchParams
                                : omit(searchParams, ['current', 'pageSize']);
                            return [2 /*return*/, (_b = (_a = service(paginationParams)) === null || _a === void 0 ? void 0 : _a.then) === null || _b === void 0 ? void 0 : _b.call(_a, formatResult)];
                        });
                    }); };
                }
                if (url) {
                    return function (searchParams) { return __awaiter(_this, void 0, void 0, function () {
                        var paginationParams, searchKey, params;
                        var _a;
                        return __generator(this, function (_b) {
                            paginationParams = pagging
                                ? searchParams
                                : omit(searchParams, ['current', 'pageSize']);
                            searchKey = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET' ? 'params' : 'data';
                            params = (_a = {},
                                _a[searchKey] = formatParam === null || formatParam === void 0 ? void 0 : formatParam(assign({}, paramsProps, paginationParams)),
                                _a);
                            return [2 /*return*/, request(__assign(__assign(__assign({}, _requestConfig), params), { url: url })).then(formatResult)];
                        });
                    }); };
                }
                return function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/, ({ list: [], total: 0 })];
                }); }); };
            }
            return takeRequestService(requestConfig);
        }, [requestConfig]);
        return {
            props: props,
            context: __assign(__assign({}, context), { requestService: requestService }),
        };
    },
    useHandlePagination: function (props, context) {
        var _a, _b, _c, _d;
        var _e = props.requestConfig, requestConfig = _e === void 0 ? {} : _e, pagging = props.pagging, pageProps = props.pageProps, onPageChange = props.onPageChange;
        var requestService = context.requestService, pageChangeEventList = context.pageChangeEventList;
        var _f = usePagination(requestService, {
            refreshDeps: Array.isArray(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.refreshDeps)
                ? requestConfig.refreshDeps
                : [],
            defaultCurrent: (_a = pageProps === null || pageProps === void 0 ? void 0 : pageProps.current) !== null && _a !== void 0 ? _a : 1,
            defaultPageSize: (_b = pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSize) !== null && _b !== void 0 ? _b : 10,
            manual: requestConfig.manual
        }), run = _f.run, runAsync = _f.runAsync, mutate = _f.mutate, data = _f.data, loading = _f.loading, pagination = _f.pagination;
        var dataSource = Array.isArray(props.dataSource)
            ? props.dataSource
            : (_c = data === null || data === void 0 ? void 0 : data.list) !== null && _c !== void 0 ? _c : [];
        var defaultActiveKey = (_d = props.defaultActiveKey) !== null && _d !== void 0 ? _d : data === null || data === void 0 ? void 0 : data.activeKey;
        return {
            props: __assign(__assign({}, props), { loading: loading, pageProps: __assign(__assign(__assign({}, pagination), { onChange: function (value) {
                        onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange(__assign(__assign({}, pick(pagination, ['pageSize', 'total', 'totalPage'])), { current: value }));
                        pagination.changeCurrent(value);
                    }, onPageSizeChange: function (value) {
                        onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange(__assign(__assign({}, pick(pagination, ['current', 'total', 'totalPage'])), { pageSize: value }));
                        pagination.onChange(1, value);
                    } }), pageProps), dataSource: dataSource, defaultActiveKey: defaultActiveKey }),
            context: __assign(__assign({}, context), { run: run, runAsync: runAsync, mutate: mutate, pagination: pagination, pageChangeEventList: pageChangeEventList }),
            render: function (_props, BaseComponents) {
                var _a = _props.pageProps, _pageProps = _a === void 0 ? {} : _a, itemLayout = _props.itemLayout;
                if (itemLayout === 'horizontal') {
                    var _b = _props.horizontalConfig, horizontalConfig = _b === void 0 ? {} : _b, children_1 = _props.children, _dataSource = _props.dataSource, renderItem_1 = _props.renderItem, otherProps = __rest(_props, ["horizontalConfig", "children", "dataSource", "renderItem"]);
                    var flex_1 = horizontalConfig.flex, flexWrap = horizontalConfig.flexWrap, flexColSpan_1 = horizontalConfig.flexColSpan;
                    var columns_1 = horizontalConfig.columns, _c = horizontalConfig.rowGap, rowGap = _c === void 0 ? 16 : _c, _d = horizontalConfig.columnGap, columnGap = _d === void 0 ? 16 : _d, _e = horizontalConfig.size, size_1 = _e === void 0 ? 'medium' : _e;
                    var resizeRef = React.useRef(null);
                    var width_1 = (useSize(resizeRef) || {}).width;
                    var innerColumns = React.useMemo(function () {
                        if (columns_1)
                            return columns_1;
                        var matched = sizeColumns[sizeColumns.length - 1][size_1];
                        if (!width_1)
                            return matched;
                        responsiveSize.some(function (v, idx) {
                            if (width_1 < v) {
                                matched = sizeColumns[idx][size_1];
                                return true;
                            }
                            return false;
                        });
                        return matched > 1 ? matched : 1;
                    }, [columns_1, width_1, size_1]);
                    var customContent = Array.isArray(_dataSource) && _dataSource.length
                        ? _dataSource.map(function (one, index) {
                            var item = null;
                            if (renderItem_1) {
                                item = renderItem_1(one, index);
                            }
                            if (flex_1) {
                                return (React.createElement(CnGrid.CnCol, { key: index, span: flexColSpan_1 }, item));
                            }
                            return (React.createElement(Cell, { key: index, colSpan: 1 }, item));
                        })
                        : _props.emptyContent;
                    var newChildren = React.useMemo(function () {
                        if (!children_1)
                            return null;
                        return React.Children.map(children_1, function (child, idx) {
                            if (flex_1) {
                                return (React.createElement(CnGrid.CnCol, { key: idx, span: flexColSpan_1 }, child));
                            }
                            return (React.createElement(Cell, { key: idx, colSpan: 1 }, child));
                        });
                    }, [children_1]);
                    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, _props.className, 'cn-list'), ref: resizeRef },
                        React.createElement(BaseComponents, __assign({}, omit(otherProps, [
                            'itemLayout',
                            'pageProps',
                            '$i18n',
                            'requestConfig',
                            'pagging',
                        ])), flex_1 ? (React.createElement(CnGrid.CnRow, { wrap: flexWrap },
                            customContent,
                            newChildren)) : (React.createElement(ResponsiveGrid, { columns: innerColumns, gap: [rowGap, columnGap] },
                            customContent,
                            newChildren)))));
                }
                return withNativeProps(props, React.createElement("div", { className: classNames('cn-list', _props.className) },
                    React.createElement(BaseComponents, __assign({}, omit(_props, [
                        'horizontalConfig',
                        'pageProps',
                        '$i18n',
                        'requestConfig',
                        'pagging',
                    ]))),
                    pagging && (React.createElement("div", null,
                        React.createElement(CnPagination, __assign({ className: "cn-list-pagination" }, _pageProps, { type: "simple", align: "center", size: "small" }))))));
            },
        };
    },
};
