import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import $i18n from 'panda-i18n';
import { formilyCore, formilyReact } from '@/form/formily';
import { Submit } from '@/form/submit';
import { Reset } from '@/form/reset';
import { createScope } from '@/form/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnPageFooter } from '@/components/cn-page-footer';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnAnchor } from '@/components/cn-anchor';
import { CnGrid } from '@/components/cn-grid';
import { HocBaseComponents, useCreatePluginInstance, } from '@/components/cn-utils';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import { events } from '@fusion/lib/util';
import { CnBox } from '@/components/cn-box';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var CnRow = CnGrid.CnRow, CnCol = CnGrid.CnCol;
var CnFormOrigin = React.forwardRef(function (props, ref) {
    var formProps = props.formProps, components = props.components, schema = props.schema, onSubmit = props.onSubmit, onReset = props.onReset, scope = props.scope, footerConfig = props.footerConfig, hasFooterSubmit = props.hasFooterSubmit, initForm = props.form, cnFormStepInstance = props.cnFormStepInstance, children = props.children, isAnchorForm = props.isAnchorForm, anchorProps = props.anchorProps;
    var formLayoutProps = __assign({ size: props.size }, props.formLayoutProps);
    var _a = anchorProps || {}, _b = _a.rightFixedSpan, rightFixedSpan = _b === void 0 ? 10 : _b, _c = _a.headingLevel, headingLevel = _c === void 0 ? [1, 2, 3] : _c, otherAnchorProps = __rest(_a, ["rightFixedSpan", "headingLevel"]);
    var lang = $i18n.getLang();
    var RealSchemaField = React.useMemo(function () { return createScope(scope); }, [scope]);
    var anchorFormRef = React.useRef();
    var _d = React.useState({}), anchorFormMountedStyle = _d[0], setAnchorFormMountedStyle = _d[1];
    var resizeObserverRef = React.useRef();
    var form = React.useMemo(function () {
        // 如果涉及到状态的更新，须传入form实例
        if (initForm)
            return initForm;
        return formilyCore.createForm(formProps);
    }, [initForm, schema]);
    React.useEffect(function () {
        formilyCore.setValidateLanguage(lang);
    }, [lang]);
    var setAnchorStyle = function () {
        if (!anchorFormRef.current || !isAnchorForm)
            return;
        // 兼容 drawer 等容器的动画
        setTimeout(function () {
            var _a = anchorFormRef.current.getBoundingClientRect(), _b = _a.left, left = _b === void 0 ? 0 : _b, _c = _a.width, width = _c === void 0 ? 0 : _c;
            setAnchorFormMountedStyle(__assign(__assign({}, anchorFormMountedStyle), { left: left + width, right: 'initial' }));
        }, 500);
    };
    React.useEffect(function () {
        if (!anchorFormRef.current || !isAnchorForm)
            return;
        var _a = anchorFormRef.current.getBoundingClientRect().top, top = _a === void 0 ? 0 : _a;
        if (top >= 0) {
            setAnchorFormMountedStyle({ top: top });
        }
        resizeObserverRef.current = new ResizeObserver(function () {
            setAnchorStyle();
        });
        resizeObserverRef.current.observe(anchorFormRef.current);
        var windowResize = debounce(setAnchorStyle, 200);
        events.on(window, 'resize', windowResize);
        return function () {
            resizeObserverRef.current.disconnect();
            events.off(window, 'resize', windowResize);
        };
    }, []);
    React.useImperativeHandle(ref, function () {
        return {
            getFormInstance: function () {
                return form;
            },
        };
    }, [form]);
    function createFormFooter(footerProps) {
        var _a = footerProps || {}, submitProps = _a.submitProps, resetProps = _a.resetProps, className = _a.className, customRender = _a.customRender, otherProps = __rest(_a, ["submitProps", "resetProps", "className", "customRender"]);
        var classes = cx('cn-ui-form-footer', className);
        if (cnFormStepInstance) {
            return (React.createElement(formilyReact.FormConsumer, null, function () { return (React.createElement(CnPageFooter, __assign({ className: classes, leftSlot: React.createElement("div", { className: "cn-ui-form-footer-header" }, cnFormStepInstance.allowBack && (React.createElement(CnButton, { "data-hottag": "cn-ui.cn-form.step-back", size: "large", onClick: function () {
                        cnFormStepInstance.back();
                    } },
                    React.createElement(CnIcon, { type: "arrow-left" }),
                    $i18n.get({
                        id: 'PreviousStep',
                        dm: '上一步',
                        ns: 'CnForm',
                    })))), rightSlot: React.createElement(CnBox, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                    React.createElement(Reset, __assign({ size: "large" }, resetProps, { onReset: onReset })),
                    !cnFormStepInstance.allowNext && (React.createElement(Submit, __assign({ size: "large" }, submitProps, { onSubmit: onSubmit, disabled: cnFormStepInstance.allowNext }))),
                    cnFormStepInstance.allowNext && (React.createElement(CnButton, { "data-hottag": "cn-ui.cn-form.step-next", size: "large", onClick: function () {
                            cnFormStepInstance.next();
                        } },
                        React.createElement(CnIcon, { type: "arrow-right" }),
                        $i18n.get({ id: 'Next', dm: '下一步', ns: 'CnForm' })))) }, otherProps))); }));
        }
        if (customRender) {
            return (React.createElement(CnPageFooter, __assign({ className: classes, rightSlot: React.createElement(formilyReact.FormConsumer, null, customRender) }, otherProps)));
        }
        return (React.createElement(CnPageFooter, __assign({ className: classes, rightSlot: React.createElement(CnBox, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                React.createElement(Reset, __assign({ size: "large" }, resetProps, { onReset: onReset })),
                React.createElement(Submit, __assign({ size: "large" }, submitProps, { onSubmit: onSubmit }))) }, otherProps)));
    }
    var formRender = function () {
        return (React.createElement(formilyReact.FormProvider, { form: form },
            React.createElement(formilyReact.ExpressionScope, { value: { $$form: form } }, withNativeProps(props, React.createElement(CnFormLayout, __assign({ "data-testid": "CnForm", "data-name": "CnForm" }, formLayoutProps, { 
                // @ts-ignore 低码组件属性, 用于可编辑表格和可编辑卡片上移、下移、删除时重新渲染
                reRender: props.reRender }),
                React.createElement(RealSchemaField, { schema: schema, components: components, scope: scope }),
                children,
                (hasFooterSubmit || footerConfig) &&
                    createFormFooter(footerConfig))))));
    };
    if (isAnchorForm) {
        return (React.createElement("div", { className: "cn-ui-anchor-form" },
            React.createElement(CnRow, null,
                React.createElement(CnCol, null,
                    React.createElement("div", { ref: anchorFormRef }, formRender())),
                React.createElement(CnCol, { fixedSpan: rightFixedSpan })),
            React.createElement(CnAnchor, __assign({ headingSelector: headingLevel
                    .map(function (level) {
                    if (level === 1)
                        return '.cn-ui-card-header-title .cn-ui-form-card-title';
                    if (level === 2)
                        return '.cn-ui-card-sub-card-header-header .cn-ui-form-card-title';
                    if (level === 3)
                        return '.cn-ui-card-sub-area-card-title-header .cn-ui-form-card-title';
                    return false;
                })
                    .filter(Boolean)
                    .join(',') }, otherAnchorProps, { style: __assign(__assign({}, anchorFormMountedStyle), ((otherAnchorProps === null || otherAnchorProps === void 0 ? void 0 : otherAnchorProps.style) || {})) }))));
    }
    return formRender();
});
CnFormOrigin.displayName = 'CnFormOrigin';
export var CnForm = React.forwardRef(function (props, ref) {
    var _a;
    var pluginIns = useCreatePluginInstance('CnForm', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnFormOrigin, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnFormOrigin, { props: props, plugins: plugins, ref: ref });
});
CnForm.defaultProps = {
    hasFooterSubmit: false,
};
CnForm.displayName = 'CnForm';
