import { __assign, __spreadArray } from "tslib";
import React, { useState, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';
import { MenuContainer, SubMenu, MenuItem } from './menu';
import './header-menu.scss';
export var HeaderMenu = function (props) {
    var menu = props.menu, onSelect = props.onSelect, menuItemIconRender = props.menuItemIconRender, defaultSelectedKeysProps = props.defaultSelectedKeys, selectedKeysProps = props.selectedKeys, triggerType = props.triggerType;
    var _a = useState(selectedKeysProps || defaultSelectedKeysProps), selectedKeys = _a[0], setSelectedKeys = _a[1];
    useEffect(function () {
        if (isEqual(selectedKeys, selectedKeysProps))
            return;
        setSelectedKeys(selectedKeysProps);
    }, [selectedKeysProps]);
    var handleSelect = function (_selectedKeys) {
        var _a;
        var other = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            other[_i - 1] = arguments[_i];
        }
        if (isFunction(onSelect))
            onSelect.apply(void 0, __spreadArray([_selectedKeys], other, false));
        if (!('selectedKeys' in props)) {
            setSelectedKeys(_selectedKeys);
        }
        (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
            name: 'CnShell页头导航菜单点击',
            eventType: 'CLK',
            hottag: 'cn-ui.cn-shell.headerMenuSelect',
        });
    };
    var handleMenuOpen = function (_selectedKeys) {
        var _a;
        if (_selectedKeys.length > 0) {
            (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
                name: 'CnShell页头导航菜单展开全部',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.headerMenuOpen',
            });
        }
    };
    return (React.createElement(MenuContainer, { hozInLine: true, direction: "hoz", mode: "popup", className: "cn-ui-shell-header-menu", popupClassName: "cn-ui-shell-header-menu-popup", popupAutoWidth: true, triggerType: triggerType, selectMode: "single", selectedKeys: selectedKeys, onSelect: handleSelect, onOpen: handleMenuOpen }, renderMenu(menu, { menuItemIconRender: menuItemIconRender, first: true })));
};
function renderMenu(menu, _a) {
    if (menu === void 0) { menu = []; }
    var menuItemIconRender = _a.menuItemIconRender, _b = _a.first, first = _b === void 0 ? false : _b;
    if (!Array.isArray(menu))
        return [];
    return menu.map(function (item) {
        var _a;
        return ((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) ? (
        // 切勿添加 key (<SubMenu key="aa" />)，否则会导致菜单展开后产生偏移
        React.createElement(SubMenu, { label: item.text }, renderMenu(item.children, { menuItemIconRender: menuItemIconRender }))) : (React.createElement(MenuItem, { key: item.key, path: item.path },
            React.createElement("a", __assign({}, item, { href: item.path, onClick: function (e) {
                    e.preventDefault();
                } }),
                item.text,
                !first ? (React.createElement("span", { className: "cn-ui-shell-header-menu-item-slot" }, menuItemIconRender === null || menuItemIconRender === void 0 ? void 0 : menuItemIconRender(item))) : null)));
    });
}
