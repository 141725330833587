import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import $i18n, { pandaEvent, langToShortLang } from 'panda-i18n';
import { datejs } from '@fusion/lib/util/index';
import * as cnUtils from '@cainiaofe/cn-utils';
import '@/locales/dayjs';
dayjs.extend(updateLocale);
// 基于标准语种返回dayjs的国际化语种
var getDayjsLang = function (lang) {
    lang = lang || $i18n.getLang();
    var lowLang = lang === null || lang === void 0 ? void 0 : lang.toLowerCase();
    if (lowLang && datejs.Ls[lowLang] && dayjs.Ls[lowLang]) {
        return lowLang;
    }
    var slang = langToShortLang(lang);
    // 兼容希伯来语(panda-i18n对于希伯来语可能存在2种版本的，dayjs只提供的he的翻译)
    slang = slang === 'iw' ? 'he' : slang;
    // 兼容英文本地语种，为了提高英文本地语种的兼容性，所有英文本地语种全部fallback到en
    slang = slang.startsWith('en-') ? 'en' : slang;
    // 不再支持范围的默认为英文
    return datejs.Ls[slang] && dayjs.Ls[slang] ? slang : 'en';
};
var changeDayjsLocale = function (locale) {
    dayjs.locale(locale);
    datejs.locale(locale);
};
function updateDayjsLocale(opts) {
    var lang = getDayjsLang($i18n.getLang());
    dayjs.updateLocale(lang, opts);
    datejs.updateLocale(lang, opts);
}
changeDayjsLocale(getDayjsLang($i18n.getLang()));
pandaEvent.on('CHANGE_LANG', function (lang) {
    changeDayjsLocale(getDayjsLang(lang));
});
export var getDateFormatConf = function (adapterLocale) {
    if (!adapterLocale || !(cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDateFormatConf)) {
        return {
            s: 'YYYY-MM-DD HH:mm:ss',
            m: 'YYYY-MM-DD HH:mm',
            H: 'YYYY-MM-DD HH',
            D: 'YYYY-MM-DD',
            M: 'YYYY-MM',
            W: 'gggg-wo',
            Q: 'YYYY-[Q]Q',
            Y: 'YYYY',
            HH: 'HH',
            Hm: 'HH:mm',
            Hms: 'HH:mm:ss',
        };
    }
    return cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDateFormatConf(adapterLocale);
};
export var dateFormatStr = function (num, formatStr, adapterLocale) {
    if (!num)
        return;
    if (!adapterLocale)
        return dayjs(num).format(formatStr);
    var formats = getDateFormatConf(adapterLocale);
    var formatConfStds = getDateFormatConf('zh-CN');
    var key = Object.keys(formatConfStds).find(function (k) { return formatConfStds[k] === formatStr; });
    var formatStrFinal = key ? formats[key] || formatStr : formatStr;
    return dayjs(num).format(formatStrFinal);
};
export { dayjs, getDayjsLang, Dayjs, updateDayjsLocale };
