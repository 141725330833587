import { __assign, __rest } from "tslib";
import './cn-breadcrumb.scss';
import * as React from 'react';
import { Breadcrumb } from '@/components/fusion';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnBreadcrumb = function (props) {
    var children = props.children, dataSource = props.dataSource, otherProps = __rest(props, ["children", "dataSource"]);
    var childNodes = [];
    if (dataSource) {
        dataSource.forEach(function (item, idx) {
            var itemChildren = item.children, otherItemProps = __rest(item, ["children"]);
            childNodes.push(React.createElement(Breadcrumb.Item, __assign({ key: idx }, otherItemProps), item.children));
        });
    }
    return withNativeProps(props, React.createElement(Breadcrumb, __assign({ "data-name": "CnBreadcrumb", separator: "/" }, otherProps), children || childNodes));
};
export var CnBreadcrumbItem = Breadcrumb.Item;
export { Breadcrumb };
