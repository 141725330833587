import { useEffect } from 'react';
import { getConeArmsTrace, extractTextFromReactNode } from "../../../utils";
/**
 * 自定义 useArmsContentReportCnMessage Hook，当 props 变化时触发 arms logPageText上报。
 *
 * @param {Object} props - 包含 type、children、title 和 shape 的属性对象。
 * @param {string} [props.type] - 信息类型。
 * @param {React.ReactNode} [props.children] - 包含文本的子节点。
 * @param {React.ReactNode} [props.title] - 包含文本的标题节点。
 * @param {string} [props.shape] - 确定组件类型。
 */
export function useArmsContentReportCnMessage(props) {
    var type = props.type, children = props.children, title = props.title, shape = props.shape;
    useEffect(function () {
        if (type) {
            cnMessageShowToUserReport({ children: children, title: title, shape: shape }, type);
        }
    }, [type, title, children, shape]);
}
/**
 * 根据所提供的 props 和类型 type 进行arms上报。
 *
 * @param {Object} props - 包含 children、title 和 shape 信息的属性对象。
 * @param {React.ReactNode} [props.children] - 包含文本的子节点。
 * @param {React.ReactNode} [props.title] - 包含文本的标题节点。
 * @param {string} [props.shape] - 确定组件类型。
 * @param {string} type - 要记录的信息类型。
 */
export function cnMessageShowToUserReport(props, type) {
    try {
        var message = typeof props === 'string'
            ? props
            : extractTextFromReactNode(props.children || props.title);
        if (message) {
            getConeArmsTrace().logPageText({
                componentType: typeof props !== 'string' && props.shape === 'addon'
                    ? 'CnConfirmButton'
                    : 'CnMessage',
                feedbackType: type,
                message: message,
            });
        }
    }
    catch (error) {
        console.error('Error logging message:', error);
    }
}
