var _a;
import { __assign } from "tslib";
import cx from 'classnames';
import $i18n from 'panda-i18n';
import React from 'react';
import { safeGetRenderKey, safeGetValue } from '../../../view/utils';
import { Colgroup } from './colgroup';
import SpanManager from './helpers/SpanManager';
import { Classes } from './styles';
import { ErrorBoundary } from './errorBoundary';
import { nativePropsReg, pickAttrsWith } from '@cainiaofe/cn-ui-common';
var isRTL = (_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n);
export function HtmlTable(_a) {
    var tbodyHtmlTag = _a.tbodyHtmlTag, getRowProps = _a.getRowProps, primaryKey = _a.primaryKey, data = _a.data, verInfo = _a.verticalRenderInfo, hozInfo = _a.horizontalRenderInfo, _b = _a.components, Row = _b.Row, Cell = _b.Cell, TableBody = _b.TableBody;
    var flat = hozInfo.flat, hoz = hozInfo.horizontalRenderRange;
    var spanManager = new SpanManager();
    var fullFlatCount = flat.full.length;
    var leftFlatCount = flat.left.length;
    var rightFlatCount = flat.right.length;
    var tbody = TableBody != null && tbodyHtmlTag === 'tbody' ? (React.createElement(TableBody, { tbodyProps: { children: data.map(renderRow) } })) : (React.createElement(tbodyHtmlTag, null, data.map(renderRow)));
    return (React.createElement("table", null,
        React.createElement(Colgroup, { descriptors: hozInfo.visible }),
        tbody));
    function renderRow(row, i) {
        var rowIndex = verInfo.offset + i;
        spanManager.stripUpwards(rowIndex);
        var rowProps = getRowProps(row, rowIndex);
        var rowClass = cx(Classes.tableRow, {
            first: rowIndex === verInfo.first,
            last: rowIndex === verInfo.last,
            even: rowIndex % 2 === 0,
            odd: rowIndex % 2 === 1,
        }, rowProps === null || rowProps === void 0 ? void 0 : rowProps.className);
        var trProps = __assign(__assign({}, rowProps), { className: rowClass, 'data-rowindex': rowIndex, children: hozInfo.visible.map(function (descriptor) {
                if (descriptor.type === 'blank') {
                    return React.createElement("td", { key: descriptor.blankSide });
                }
                // 渲染单元格
                return renderBodyCell(row, rowIndex, descriptor.col, descriptor.colIndex);
            }) });
        // 此处发现特殊问题，如果在一次渲染中使用了两个相同的 primaryKey，会导致下一次渲染时额外渲染了一行
        // 最后结论是不解决，要求业务侧不要在一次渲染中使用两个相同的 primaryKey
        var key = safeGetRenderKey(primaryKey, row, rowIndex);
        if (Row != null && tbodyHtmlTag === 'tbody') {
            return React.createElement(Row, { key: key, row: row, rowIndex: rowIndex, trProps: trProps });
        }
        else {
            return React.createElement("tr", __assign({ key: key }, trProps));
        }
    }
    function renderBodyCell(row, rowIndex, column, colIndex) {
        var _a, _b;
        if (spanManager.testSkip(rowIndex, colIndex)) {
            return null;
        }
        var dataProps = pickAttrsWith(column, nativePropsReg);
        var value = safeGetValue(column, row, rowIndex);
        var cellProps = (_b = (_a = column.getCellProps) === null || _a === void 0 ? void 0 : _a.call(column, value, row, rowIndex)) !== null && _b !== void 0 ? _b : {};
        var cellContent = value;
        if (column.render) {
            cellContent = column.render(value, row, rowIndex);
        }
        var colSpan = 1;
        var rowSpan = 1;
        if (column.getSpanRect) {
            var spanRect = column.getSpanRect(value, row, rowIndex);
            colSpan = spanRect == null ? 1 : spanRect.right - colIndex;
            rowSpan = spanRect == null ? 1 : spanRect.bottom - rowIndex;
        }
        else {
            if (cellProps.colSpan != null) {
                colSpan = cellProps.colSpan;
            }
            if (cellProps.rowSpan != null) {
                rowSpan = cellProps.rowSpan;
            }
        }
        // rowSpan/colSpan 不能过大，避免 rowSpan/colSpan 影响因虚拟滚动而未渲染的单元格
        rowSpan = Math.min(rowSpan, verInfo.limit - rowIndex);
        colSpan = Math.min(colSpan, leftFlatCount + hoz.rightIndex - colIndex);
        var hasSpan = colSpan > 1 || rowSpan > 1;
        if (hasSpan) {
            spanManager.add(rowIndex, colIndex, colSpan, rowSpan);
        }
        var positionStyle = {};
        if (colIndex < leftFlatCount) {
            positionStyle.position = 'sticky';
            isRTL ? positionStyle.right = hozInfo.stickyLeftMap.get(colIndex) :
                positionStyle.left = hozInfo.stickyLeftMap.get(colIndex);
        }
        else if (colIndex >= fullFlatCount - rightFlatCount) {
            positionStyle.position = 'sticky';
            isRTL ? positionStyle.left = hozInfo.stickyRightMap.get(colIndex) :
                positionStyle.right = hozInfo.stickyRightMap.get(colIndex);
        }
        var tdProps = __assign(__assign(__assign(__assign(__assign({}, dataProps), cellProps), { className: cx(Classes.tableCell, cellProps.className, {
                first: colIndex === 0,
                last: colIndex + colSpan === fullFlatCount,
                'lock-left': colIndex < leftFlatCount,
                'lock-right': colIndex >= fullFlatCount - rightFlatCount,
            }) }), (hasSpan ? { colSpan: colSpan, rowSpan: rowSpan } : null)), { style: __assign(__assign({ textAlign: column.align }, cellProps.style), positionStyle), children: cellContent });
        if (Cell != null && tbodyHtmlTag === 'tbody') {
            return (React.createElement(ErrorBoundary, { key: colIndex },
                React.createElement(Cell, { key: colIndex, tdProps: tdProps, row: row, rowIndex: rowIndex, column: column, colIndex: colIndex })));
        }
        else {
            return (React.createElement(ErrorBoundary, { key: colIndex },
                React.createElement("td", __assign({ key: colIndex }, tdProps))));
        }
    }
}
