import { __assign, __spreadArray } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { formilyReact, formilyShared } from '@/form/formily';
import { useArray } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var isValid = formilyShared.isValid, clone = formilyShared.clone;
var useField = formilyReact.useField;
var getSchemaDefaultValue = function (schema) {
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'array')
        return [];
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'object')
        return {};
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'void') {
        for (var key in schema.properties) {
            var value = getSchemaDefaultValue(schema.properties[key]);
            if (isValid(value))
                return value;
        }
    }
};
var getDefaultValue = function (defaultValue, schema) {
    if (isValid(defaultValue))
        return clone(defaultValue);
    if (Array.isArray(schema === null || schema === void 0 ? void 0 : schema.items)) {
        return getSchemaDefaultValue(schema.items[0]);
    }
    return getSchemaDefaultValue(schema.items);
};
export var Addition = function (props) {
    var self = useField();
    var array = useArray();
    if (!array || !array.field)
        return null;
    var schema = array.schema, field = array.field, _a = array.props, arrayTableProps = _a === void 0 ? {} : _a;
    if (field.pattern !== 'editable' && field.pattern !== 'disabled') {
        return null;
    }
    return (React.createElement(CnButton, __assign({}, props, { disabled: self === null || self === void 0 ? void 0 : self.disabled, className: classNames("".concat(cssPrefix, "-addition"), props.className), onClick: function (e) {
            var _a, _b, _c, _d, _e;
            if (arrayTableProps.disabled)
                return;
            e.stopPropagation();
            var value = field.value || [];
            // 新增数据的数组中的索引
            var newValueIndex = props.method === 'unshift' ? 0 : value.length;
            var propsDefaultValue = typeof props.defaultValue === 'function'
                ? props.defaultValue()
                : props.defaultValue;
            var defaultValue = getDefaultValue(propsDefaultValue, schema);
            if (props.method === 'unshift') {
                (_a = field.unshift) === null || _a === void 0 ? void 0 : _a.call(field, defaultValue);
                (_b = arrayTableProps.onAdd) === null || _b === void 0 ? void 0 : _b.call(arrayTableProps, newValueIndex);
            }
            else {
                (_c = field.push) === null || _c === void 0 ? void 0 : _c.call(field, defaultValue);
                // 新增一行时数据置空
                if (value.length) {
                    var newValue_1 = __spreadArray([], value, true);
                    newValue_1[newValueIndex] = propsDefaultValue !== null && propsDefaultValue !== void 0 ? propsDefaultValue : {};
                    setTimeout(function () {
                        field.setValue(newValue_1);
                    });
                }
                (_d = arrayTableProps.onAdd) === null || _d === void 0 ? void 0 : _d.call(arrayTableProps, newValueIndex);
            }
            (_e = props.onClick) === null || _e === void 0 ? void 0 : _e.call(props, e);
        } }),
        React.createElement(CnIcon, { type: "icon-add", size: "small" }),
        props.title || self.title));
};
