var _a;
import { __assign, __rest } from "tslib";
import './cn-icon-list.scss';
import './cn-icon.scss';
import './rtl.scss';
import * as React from 'react';
import { ICON_SCRIPT_URL, ICON_RTL_SCRIPT_URL } from '@cainiaofe/cn-ui-common';
import isString from 'lodash/isString';
import { createFromIconfontCN } from '../icon-font';
import classNames from 'classnames';
import $i18n from 'panda-i18n';
var CustomIcon = createFromIconfontCN({
    scriptUrl: ((_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n)) ? ICON_RTL_SCRIPT_URL : ICON_SCRIPT_URL,
});
// 一些废弃的 Icon 如何映射为新 Icon
var historyIconMap = {
    'a-datasource-fill': 'data-source-fill',
    'a-datasource': 'data-source',
    'a-coffeecup1-fill': 'coffee-cup1-fill',
    'a-coffeecup': 'coffee-cup',
    'a-coffeecup1': 'coffee-cup1',
    'a-coffeecup-fill': 'coffee-cup-fill',
    'a-platformscale': 'platform-scale',
    'a-platformscale-fill': 'platform-scale-fill',
    'a-creditcard': 'credit-card',
    'a-FormatPainter': 'format-painter',
    'printer1-fill': 'printer1-fill',
    'supply-chain-fill1': 'supply-chain2',
    'addressbook-fill': 'address-book-fill',
    'phonebook-fill': 'phone-book-fill',
    phonebook: 'phone-book',
    addressbook: 'address-book',
    'a-warehouse-inout': 'warehouse-inout',
    'a-warehouse-inout-fill': 'warehouse-inout-fill',
    'screen-money-fill1': 'screen-money',
    'a-postmail-fill': 'post-mail-fill',
    'a-postmail': 'post-mail',
    'a-batchinput': 'batch-input',
    'a-2b-fulfillment-service-fill': 'tob-fulfillment-service-fill',
    'a-2b-fulfillment-service': 'tob-fulfillment-service',
    'a-2c-fulfillment-service': 'toc-fulfillment-service',
    'a-2c-fulfillment-service-fill': 'toc-fulfillment-service-fill',
};
export var CnIcon = function (props) {
    var type = props.type, outerClassName = props.outerClassName, otherProps = __rest(props, ["type", "outerClassName"]);
    var realType = type || '';
    // 一些废弃的 Icon 映射为新 Icon
    if (historyIconMap[type]) {
        realType = historyIconMap[type];
    }
    // 由于原始 Icon 是 icon- 开头的，所以需要转换
    if (isString(realType) && !realType.startsWith('icon-')) {
        realType = "icon-".concat(realType);
    }
    return (React.createElement(CustomIcon, __assign({}, otherProps, { outerClassName: classNames(outerClassName, 'cn-ui-icon'), type: realType })));
};
CnIcon.createFromIconfontCN = createFromIconfontCN;
