import { __assign } from "tslib";
import * as React from 'react';
import NextStep from './view/step';
import NextStepItem from './view/step-item';
import { CnPageFooter } from '@/components/cn-page-footer';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnStep = React.forwardRef(function (props, ref) {
    var hasCnPageFooter = props.hasCnPageFooter, cnPageFooterProps = props.cnPageFooterProps;
    return withNativeProps(props, React.createElement(React.Fragment, null,
        React.createElement(NextStep, __assign({ "data-name": "CnStep" }, props, { ref: ref })),
        hasCnPageFooter && React.createElement(CnPageFooter, __assign({}, cnPageFooterProps))));
});
CnStep.displayName = 'CnStep';
export var CnStepItem = NextStepItem;
/**
 * @deprecated
 * 建议使用 CnStepItem
 */
CnStep.Item = NextStepItem;
/**
 * @deprecated
 * 建议使用 CnStepItem
 */
export var StepItem = NextStepItem;
StepItem.displayName = 'StepItem';
