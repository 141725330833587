import { __assign } from "tslib";
import './view/cn-menu.scss';
import React from 'react';
import NextMenu, { Item, SubMenu, Divider, Group, CheckboxItem, RadioItem, PopupItem, } from '@fusion/lib/menu';
import $i18n from 'panda-i18n';
export function CnMenu(props) {
    return React.createElement(NextMenu, __assign({ "data-name": "CnMenu" }, props, { rtl: $i18n.isRTL(), popupProps: __assign(__assign({}, (props.popupProps || {})), { rtl: $i18n.isRTL() }) }));
}
CnMenu.displayName = 'CnMenu';
CnMenu.isNextMenu = true;
CnMenu.create = NextMenu.create;
/**
 * @deprecated  请使用 CnMenuItem 替换
 */
CnMenu.Item = Item;
/**
 * @deprecated  请使用 CnMenuSubMenu 替换
 */
CnMenu.SubMenu = SubMenu;
/**
 * @deprecated  请使用 CnMenuDivider 替换
 */
CnMenu.Divider = Divider;
/**
 * @deprecated  请使用 CnMenuGroup 替换
 */
CnMenu.Group = Group;
/**
 * @deprecated  请使用 CnMenuCheckboxItem 替换
 */
CnMenu.CheckboxItem = CheckboxItem;
/**
 * @deprecated  请使用 CnMenuRadioItem 替换
 */
CnMenu.RadioItem = RadioItem;
/**
 * @deprecated  请使用 CnMenuPopupItem 替换
 */
CnMenu.PopupItem = PopupItem;
export var CnMenuItem = Item;
export var CnMenuGroup = Group;
export var CnMenuDivider = Divider;
export var CnMenuSubMenu = SubMenu;
export var CnMenuCheckboxItem = CheckboxItem;
export var CnMenuRadioItem = RadioItem;
export var CnMenuPopupItem = PopupItem;
/**
 * @deprecated  请使用 CnMenu 替换
 */
export var Menu = NextMenu;
