import { __assign, __rest } from "tslib";
import './view/cn-range.scss';
import * as React from 'react';
import { Range as NextRange } from '@/components/fusion';
import { CnReadOnly } from '@/components/cn-read-only';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnRange = React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
    var renderPreview = function (value) {
        return React.createElement(CnReadOnly, __assign({ value: value, type: "string" }, readOnlyProps));
    };
    return withNativeProps(props, React.createElement(NextRange, __assign({ "data-name": "CnRange", 
        // @ts-ignore fusion 类型提示暂时忽略
        ref: ref, isPreview: readOnly, renderPreview: renderPreview }, otherProps)));
});
CnRange.displayName = 'CnRange';
