import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { cssPrefix } from './constant';
export default function ImportButton(props) {
    var children = props.children, onClick = props.onClick, buttonProps = props.buttonProps, otherProps = props.otherProps, buttonText = props.buttonText;
    if (children) {
        return (React.createElement("span", { className: "".concat(cssPrefix, "button-wrapper"), onClick: onClick }, children));
    }
    return (React.createElement(CnButton, __assign({}, buttonProps, otherProps, { iconSize: "medium", icons: {
            loading: React.createElement(CnIcon, { type: "import-server" }),
        }, onClick: onClick }), buttonText || $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' })));
}
