import { __assign, __rest } from "tslib";
import './cn-cascader-select.scss';
import { getPopupContainer, useCnRequest, withNativeProps, dataSourceI18n } from '@cainiaofe/cn-ui-common';
// 第三方依赖
import * as React from 'react';
import classNames from 'classnames';
import $i18n from 'panda-i18n';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
// 其他组件
import { CnTooltip } from '@/components/cn-tooltip';
import { CascaderSelect as NextCascaderSelect } from '@/components/fusion';
import { CnReadOnly } from '@/components/cn-read-only';
import { i18nRegister } from '@/components/cn-utils';
// 相对依赖
import { findDeep } from './utils';
import { handleErrorInfo } from './handle-error-info';
import { handleLoading } from './handle-loading';
// 导出一个名为 CnCascaderSelect 的 React 函数组件，该组件接收 CnCascaderSelectProps 类型的 props
export var CnCascaderSelect = React.forwardRef(function (props, ref) {
    // 从 props 中解构出各个属性
    var className = props.className, readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, popupContainer = props.popupContainer, requestConfig = props.requestConfig, onVisibleChangeProps = props.onVisibleChange, onChangeProps = props.onChange, filterLocal = props.filterLocal, enableRemoteLazyLoad = props.enableRemoteLazyLoad, isArrayValue = props.isArrayValue, otherProps = __rest(props, ["className", "readOnly", "readOnlyProps", "popupContainer", "requestConfig", "onVisibleChange", "onChange", "filterLocal", "enableRemoteLazyLoad", "isArrayValue"]);
    if (Array.isArray(otherProps.dataSource)) {
        dataSourceI18n(otherProps.dataSource);
    }
    // 定义一个名为 innerDataSource 的 state，初始值为空数组
    var _a = React.useState([]), innerDataSource = _a[0], setInnerDataSource = _a[1];
    // 定义一个名为 existValues 的 ref，初始值为 undefined
    var existValues = React.useRef();
    // 定义一个名为 remoteLazyLoadItemRef 的 ref，初始值为 undefined
    var remoteLazyLoadItemRef = React.useRef();
    // 定义一个名为 selectRef 的 ref，初始值为 null
    var selectRef = React.useRef(null);
    // 定义一个名为 insertSelectProps 的对象，用于存储 CascaderSelect 组件的 props
    var insertSelectProps = {};
    // 定义一个名为 isRemoteDataSource 的 useMemo，用于判断是否是远程数据源
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    // 如果 requestConfig 中没有 resultFormatter 和 formatResult 属性，则为其添加一个默认的 formatResult 方法
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return dataSourceI18n(res);
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return dataSourceI18n(res.data);
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return dataSourceI18n(res.data.dataSource);
            }
            return [];
        };
    }
    // 使用 useCnRequest 自定义 hook 发送请求，获取数据
    var cnRequestConfig = __assign({ ready: isRemoteDataSource }, requestConfig);
    // 兼容自定义service逻辑（通用hook不处理非cn-request的service）
    if (isFunction(requestConfig.service)) {
        cnRequestConfig.service = function (searchConfig) {
            var _a;
            return (_a = requestConfig.service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                var dataSource = requestConfig.formatResult(data);
                return Array.isArray(dataSource) ? dataSourceI18n(dataSource) : [];
            });
        };
    }
    var _b = useCnRequest(cnRequestConfig), run = _b.run, runAsync = _b.runAsync, error = _b.error, data = _b.data, loading = _b.loading, mutate = _b.mutate;
    // 使用 React.useImperativeHandle 设置 ref 的实例值
    React.useImperativeHandle(ref, function () {
        var _a;
        return __assign({ mutateDataSource: mutate, sendRequest: run }, ((_a = selectRef.current) !== null && _a !== void 0 ? _a : {}));
    }, [selectRef]);
    // 定义一个名为 maxTagPlaceholder 的函数，用于生成标签占位符
    var maxTagPlaceholder = function (selectedValues) {
        var trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
            $i18n.get({ id: 'TERM.Selected_M1oYq', dm: '已选择', ns: 'CnCascaderSelect' }), "".concat(selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length),
            $i18n.get({ id: 'Item', dm: '项', ns: 'CnCascaderSelect' })));
        var labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map(function (obj) {
            if (typeof obj === 'object') {
                return obj.label;
            }
            return obj;
        });
        return (React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', ')));
    };
    // 使用 React.useEffect 监听 props.value 的变化，如果 existValues 不等于 props.value，则清空 existValues
    React.useEffect(function () {
        if (!('value' in props))
            return;
        var ev = existValues.current;
        ev = Array.isArray(ev) ? ev.map(function (i) { return i.value; }) : ev === null || ev === void 0 ? void 0 : ev.value;
        if (!isEqual(ev, props.value)) {
            existValues.current = undefined;
        }
    }, [props.value]);
    // 使用 React.useEffect 监听 data 的变化，如果 data 变化，则更新 innerDataSource
    React.useEffect(function () {
        if (loading)
            return;
        setInnerDataSource(function (previousDataSource) {
            var _a;
            var rst = [];
            var ev = existValues.current;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                var valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return previousDataSource;
            }
            if (Array.isArray(ev)) {
                ev.length > 0 &&
                    ev.forEach(function (i) {
                        if (!findDeep(data, 'value', i.value)) {
                            rst.push({
                                value: i.value,
                                label: i.label,
                            });
                        }
                    });
                return data.concat(rst);
            }
            if (ev && ev.value && !findDeep(data, 'value', ev.value)) {
                rst.push({
                    value: ev.value,
                    label: ev.label,
                });
                return data.concat(rst);
            }
            return data;
        });
    }, [data]);
    // 将 innerDataSource 赋值给 insertSelectProps.dataSource
    insertSelectProps.dataSource = innerDataSource || [];
    // 如果是远程数据源，则将 insertSelectProps.resultAutoWidth 设置为 false
    if (isRemoteDataSource) {
        insertSelectProps.resultAutoWidth = false;
    }
    // 调用 handleErrorInfo 函数处理错误信息，并将返回的对象赋值给 errorInfo
    var errorInfo = handleErrorInfo(error);
    // 调用 handleLoading 函数处理加载状态，并将返回的对象赋值给 loadingInfo
    var loadingInfo = handleLoading(loading, data);
    // 将 errorInfo 和 loadingInfo 合并到 insertSelectProps 中
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // 如果开启了远程异步加载数据，则关闭搜索功能，并设置 loadData 方法
    if (enableRemoteLazyLoad) {
        otherProps.showSearch = false;
        otherProps.loadData = function (item) {
            var _a;
            remoteLazyLoadItemRef.current = item;
            return runAsync((_a = {},
                _a[requestConfig.remoteLazyLoadKey || 'value'] = item.value,
                _a)).catch(function (err) {
                console.log(err);
            });
        };
    }
    // 如果开启了搜索功能且没有设置 onSearch 方法且 filterLocal 为 false，则设置 onSearch 方法和 filter 方法
    var enableOnSearch = otherProps.showSearch && !otherProps.onSearch && !filterLocal;
    if (enableOnSearch) {
        insertSelectProps.onSearch = function (inputValue) {
            var _a;
            run((_a = {},
                _a[requestConfig.searchKey || 'key'] = inputValue,
                _a));
        };
        insertSelectProps.filter = function () { return true; };
    }
    // 定义一个名为 onVisibleChange 的函数，用于处理下拉框显示隐藏的状态变化
    var onVisibleChange = function (visible, type) {
        if (typeof onVisibleChangeProps === 'function') {
            onVisibleChangeProps(visible, type, run);
        }
    };
    // 定义一个名为 onChange 的函数，用于处理选中值的变化
    var onChange = function (v, d, e) {
        var _a;
        if (isRemoteDataSource) {
            existValues.current = d;
            if (!v || !v.length) {
                run((_a = {},
                    _a[requestConfig.searchKey || 'key'] = '',
                    _a));
            }
        }
        if (typeof onChangeProps === 'function') {
            if (isArrayValue === true) {
                onChangeProps([v], [d], e);
            }
            else {
                onChangeProps(v, d, e);
            }
        }
    };
    // 定义一个名为 renderPreview 的函数，用于渲染预览模式下的值
    var renderPreview = function (values) {
        var otherPlaceProps = pick(props, [
            'addonBefore',
            'innerBefore',
            'addonTextBefore',
            'addonTextAfter',
            'innerAfter',
            'addonAfter',
        ]);
        return (React.createElement(CnReadOnly, __assign({ type: "enum", value: values, valueSeparator: " / " }, otherPlaceProps, readOnlyProps)));
    };
    // 返回一个 NextCascaderSelect 组件
    return withNativeProps(props, React.createElement(NextCascaderSelect, __assign({ "data-testid": "CnCascaderSelect", "data-name": "CnCascaderSelect", ref: selectRef, isPreview: readOnly, renderPreview: renderPreview, maxTagPlaceholder: maxTagPlaceholder, popupContainer: getPopupContainer(popupContainer) }, insertSelectProps, omit(otherProps, ['$i18n']), { className: classNames('cn-ui-cascader-select', className), onVisibleChange: onVisibleChange, onChange: onChange, popupProps: { rtl: $i18n.isRTL() } })));
});
CnCascaderSelect.displayName = 'CnCascaderSelect';
CnCascaderSelect.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    requestConfig: {},
    readOnlyProps: {
        valueSeparator: ' / ',
        type: 'enum',
    },
    filterLocal: true,
    isArrayValue: false,
};
i18nRegister(CnCascaderSelect);
