import React, { createContext, useContext } from 'react';
export var CnLocalizationContext = createContext((window === null || window === void 0 ? void 0 : window.__CNUI_LOCALIZATION_CONFIG__) || {});
export function CnLocalizationProvider(_a) {
    var children = _a.children, numAdapterLocale = _a.numAdapterLocale, dateAdapterLocale = _a.dateAdapterLocale;
    return (React.createElement(CnLocalizationContext.Provider, { value: {
            numAdapterLocale: numAdapterLocale,
            dateAdapterLocale: dateAdapterLocale,
        } }, children));
}
export function useLocalization(type, adapterLocale) {
    var context = useContext(CnLocalizationContext) || {};
    if (adapterLocale !== undefined) {
        return adapterLocale;
    }
    if (!type) {
        return context;
    }
    return context[type];
}
