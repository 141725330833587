import React from 'react';
/**
 * 从 React 子节点递归提取文本内容。
 *
 * @param {React.ReactNode} children - 要提取文本内容的 React 节点。
 * @returns {string} - 提取的字符串文本内容。
 */
export function extractTextFromReactNode(node) {
    var textContent = '';
    var appendText = function (reactNode) {
        if (typeof reactNode === 'string' || typeof reactNode === 'number') {
            textContent += reactNode.toString();
        }
        else if (React.isValidElement(reactNode)) {
            appendText(reactNode.props.children);
        }
        else if (Array.isArray(reactNode)) {
            reactNode.forEach(appendText);
        }
    };
    appendText(node);
    return textContent.slice(0, 200);
}
