import { __assign, __rest } from "tslib";
import React from 'react';
import cx from 'classnames';
import { Icon } from '@/components/fusion';
var customCache = new Set();
/** createFromIconfontCN
 *  @description 通过自定义 iconfont源来使用使用svg格式的图片
 */
export function createFromIconfontCN(options) {
    var scriptUrl = options.scriptUrl, _a = options.extraCommonProps, extraCommonProps = _a === void 0 ? {} : _a;
    var hasExist = customCache.has(scriptUrl);
    if (document.querySelector("script[data-namespace=\"".concat(scriptUrl, "\"]"))) {
        hasExist = true;
    }
    /**
     * DOM API required.
     * Make sure in browser environment.
     * The Custom Icon will create a <script/>
     * that loads SVG symbols and insert the SVG Element into the document body.
     */
    if (typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        typeof document.createElement === 'function' &&
        typeof scriptUrl === 'string' &&
        scriptUrl.length &&
        !hasExist &&
        document.body) {
        var script = document.createElement('script');
        script.setAttribute('src', scriptUrl);
        script.setAttribute('data-namespace', scriptUrl);
        customCache.add(scriptUrl);
        document.body.appendChild(script);
    }
    var Iconfont = function (props) {
        var _a;
        var type = props.type, size = props.size, children = props.children, className = props.className, outerClassName = props.outerClassName, _b = props.prefix, prefix = _b === void 0 ? 'cn-next-' : _b, others = __rest(props, ["type", "size", "children", "className", "outerClassName", "prefix"]);
        var content = null;
        if (props.type) {
            content = React.createElement("use", { xlinkHref: "#".concat(type) });
        }
        if (children) {
            content = children;
        }
        var isSupportNumberSize = typeof size === 'number' && size >= 8 && size <= 48 && size % 2 === 0;
        return (React.createElement(Icon, { "data-name": "CnIcon", "data-type": type, size: isSupportNumberSize ? undefined : size, className: cx(outerClassName, (_a = {},
                _a["cn-ui-icon-size-".concat(size)] = isSupportNumberSize,
                _a)) },
            React.createElement("svg", __assign({ className: cx("".concat(prefix, "icon-remote"), className), focusable: false }, others, extraCommonProps), content)));
    };
    Iconfont.displayName = 'Iconfont';
    return Iconfont;
}
