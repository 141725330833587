import { __assign, __extends, __rest, __spreadArray } from "tslib";
// @ts-nocheck
/* eslint-disable max-depth */
import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { polyfill } from 'react-lifecycles-compat';
import classNames from 'classnames';
import { func, dom, obj, KEYCODE } from '@fusion/lib/util';
import TreeNode from './tree-node';
import VirtualList from '@fusion/lib/virtual-list';
import { normalizeToArray, isDescendantOrSelf, isSiblingOrSelf, filterChildKey, filterParentKey, getAllCheckedKeys, forEachEnableNode, isNodeChecked, getAllDescendantKeys, convertChildren2Data, } from './util';
var bindCtx = func.bindCtx, noop = func.noop;
var getOffset = dom.getOffset;
var pickOthers = obj.pickOthers, isPlainObject = obj.isPlainObject;
export var treeNodeProps = [
    'key',
    'label',
    'selectable',
    'checkable',
    'editable',
    'draggable',
    'disabled',
    'checkboxDisabled',
    'isLeaf',
    'icon',
];
var getExpandedKeys = function (props, willReceiveProps, expandedKeysFromState, _k2n, _p2n) {
    var expandedKeys;
    if (props.expandAll || (!willReceiveProps && props.defaultExpandAll)) {
        expandedKeys = Object.keys(_k2n).filter(function (key) {
            var children = _k2n[key].children;
            return children && children.length;
        });
    }
    else {
        if ('expandedKeys' in props) {
            // 强受控状态，优先级最高
            expandedKeys = props.expandedKeys;
        }
        else if (willReceiveProps) {
            // 组件的历史代码，willReceiveProps true 代表非初始化的状态
            // expandedKeysFromState来自于state.expandedKeys, 非受控状态时候，它在初始化的时候，为undefined，这个时候需要读取defaultExpandedKeys
            expandedKeys = expandedKeysFromState !== null && expandedKeysFromState !== void 0 ? expandedKeysFromState : props.defaultExpandedKeys;
        }
        else {
            // 组件的历史代码，willReceiveProps false 代表初始化的状态，这个时候非受控状态下，直接使用defaultExpandedKeys
            expandedKeys = props.defaultExpandedKeys;
        }
        expandedKeys = normalizeToArray(expandedKeys);
        if (props.autoExpandParent) {
            var newExpandedKeys_1 = [];
            var expandedPoss = expandedKeys === null || expandedKeys === void 0 ? void 0 : expandedKeys.reduce(function (ret, key) {
                var pos = _k2n[key] && _k2n[key].pos;
                if (pos) {
                    ret.push(pos);
                    newExpandedKeys_1.push(key);
                }
                return ret;
            }, []);
            expandedPoss.forEach(function (pos) {
                var nums = pos.split('-');
                if (nums.length === 2) {
                    return;
                }
                for (var i = 1; i <= nums.length - 2; i++) {
                    var ancestorPos = nums.slice(0, i + 1).join('-');
                    var ancestorKey = _p2n[ancestorPos].key;
                    if (newExpandedKeys_1.indexOf(ancestorKey) === -1) {
                        newExpandedKeys_1.push(ancestorKey);
                    }
                }
            });
            // newExpandedKeys默认设置了[], 他会强行改变 state.expandedKeys的初始状态，目前是否使用 defaultExpandKeys 是通过state.expandedKeys是否处于初始状态来判断
            return (newExpandedKeys_1 === null || newExpandedKeys_1 === void 0 ? void 0 : newExpandedKeys_1.length) > 0
                ? newExpandedKeys_1
                : expandedKeysFromState;
        }
    }
    return expandedKeys;
};
var getSelectedKeys = function (props, willReceiveProps, _k2n) {
    var selectedKeys;
    if ('selectedKeys' in props) {
        selectedKeys = props.selectedKeys;
    }
    else if (willReceiveProps) {
        selectedKeys = [];
    }
    else {
        selectedKeys = props.defaultSelectedKeys;
    }
    selectedKeys = normalizeToArray(selectedKeys);
    return selectedKeys.filter(function (key) { return _k2n[key]; });
};
var getIndeterminateKeys = function (checkedKeys, checkStrictly, _k2n, _p2n) {
    if (checkStrictly) {
        return [];
    }
    var indeterminateKeys = [];
    var poss = filterChildKey(checkedKeys
        .filter(function (key) { return !!_k2n[key]; })
        .filter(function (key) {
        return !_k2n[key].disabled &&
            !_k2n[key].checkboxDisabled &&
            _k2n[key].checkable !== false;
    }), _k2n, _p2n).map(function (key) { return _k2n[key].pos; });
    poss.forEach(function (pos) {
        var nums = pos.split('-');
        for (var i = nums.length; i > 2; i--) {
            var parentPos = nums.slice(0, i - 1).join('-');
            var parent = _p2n[parentPos];
            if (parent.disabled || parent.checkboxDisabled)
                break;
            var parentKey = parent.key;
            if (indeterminateKeys.indexOf(parentKey) === -1) {
                indeterminateKeys.push(parentKey);
            }
        }
    });
    return indeterminateKeys;
};
var getCheckedKeys = function (props, willReceiveProps, _k2n, _p2n) {
    var checkedKeys = props.defaultCheckedKeys;
    var indeterminateKeys;
    if ('checkedKeys' in props) {
        checkedKeys = props.checkedKeys;
    }
    else if (willReceiveProps) {
        checkedKeys = [];
    }
    var checkStrictly = props.checkStrictly; // TODO TEST
    if (checkStrictly) {
        if (isPlainObject(checkedKeys)) {
            var checked = checkedKeys.checked, indeterminate = checkedKeys.indeterminate;
            checkedKeys = normalizeToArray(checked);
            indeterminateKeys = normalizeToArray(indeterminate);
        }
        else {
            checkedKeys = normalizeToArray(checkedKeys);
        }
        checkedKeys = checkedKeys.filter(function (key) { return !!_k2n[key]; });
    }
    else {
        checkedKeys = getAllCheckedKeys(checkedKeys, _k2n, _p2n);
        checkedKeys = checkedKeys.filter(function (key) { return !!_k2n[key]; });
        indeterminateKeys = getIndeterminateKeys(checkedKeys, props.checkStrictly, _k2n, _p2n);
    }
    return { checkedKeys: checkedKeys, indeterminateKeys: indeterminateKeys };
};
var preHandleData = function (dataSource, props) {
    var k2n = {};
    var p2n = {};
    var keyList = [];
    var drill = function (data, level, prefix, parent) {
        if (data === void 0) { data = []; }
        if (level === void 0) { level = 1; }
        if (prefix === void 0) { prefix = '0'; }
        return data.map(function (item, index) {
            // 为了兼容之前的实现 保留非法节点
            if (item.illegalFlag) {
                return item;
            }
            var children = item.children;
            var pos = "".concat(prefix, "-").concat(index);
            var key = item.key;
            item.pos = pos;
            item.level = level;
            // 判断为叶子节点
            // - 指定isLeaf属性
            // - loadData模式下 没有指定isLeaf为true
            // - 存在children元素
            if (!('isLeaf' in item)) {
                item.isLeaf = !((children && children.length) || props.loadData);
            }
            if (!('selectable' in item) && props.isOnlyLeafNodeSelectable) {
                item.selectable = !!item.isLeaf;
            }
            item.isLastChild = parent
                ? [].concat(parent.isLastChild || [], index === data.length - 1)
                : [];
            if (key === undefined || key === null) {
                key = pos;
                item.key = key;
            }
            keyList.push(key);
            !item.isLeaf && drill(children, level + 1, pos, item);
            var itemCopy = __assign({}, item);
            k2n[key] = itemCopy;
            p2n[pos] = itemCopy;
            return item;
        });
    };
    return { dataSource: drill(dataSource), k2n: k2n, p2n: p2n, keyList: keyList };
};
var preHandleChildren = function (props) {
    var k2n = {};
    var p2n = {};
    var keyList = [];
    var loop = function (children, prefix, level) {
        if (prefix === void 0) { prefix = '0'; }
        if (level === void 0) { level = 1; }
        return Children.map(children, function (node, index) {
            if (!React.isValidElement(node)) {
                return;
            }
            var pos = "".concat(prefix, "-").concat(index);
            var key = node.key;
            key = key || pos;
            var item = __assign(__assign({}, node.props), { key: key, pos: pos, level: level });
            var _children = node.props.children;
            var hasChildren = _children && Children.count(_children);
            if (!('isLeaf' in item)) {
                item.isLeaf = !(hasChildren || props.loadData);
            }
            if (!('selectable' in item) && props.isOnlyLeafNodeSelectable) {
                item.selectable = !!item.isLeaf;
            }
            keyList.push(key);
            if (hasChildren) {
                item.children = loop(_children, pos, level + 1);
            }
            k2n[key] = item;
            p2n[pos] = item;
            return item;
        });
    };
    loop(props.children);
    return { k2n: k2n, p2n: p2n, keyList: keyList };
};
var getData = function (props) {
    var dataSource = props.dataSource, renderChildNodes = props.renderChildNodes, _a = props.children, children = _a === void 0 ? [] : _a, useVirtual = props.useVirtual, immutable = props.immutable;
    var data = immutable ? cloneDeep(dataSource) : dataSource;
    if ((renderChildNodes || useVirtual) && !('dataSource' in props)) {
        data = convertChildren2Data(children);
    }
    if (data) {
        try {
            return preHandleData(data, props);
        }
        catch (err) {
            if ((err.message || '').match('object is not extensible')) {
                // eslint-disable-next-line no-console
                console.error(err.message, 'try to set immutable to true to allow immutable dataSource');
            }
            else {
                throw err;
            }
        }
    }
    else {
        return preHandleChildren(props);
    }
};
/**
 * Tree
 */
var Tree = /** @class */ (function (_super) {
    __extends(Tree, _super);
    function Tree(props) {
        var _this = _super.call(this, props) || this;
        var _a = getData(props), _b = _a.dataSource, dataSource = _b === void 0 ? [] : _b, k2n = _a.k2n, p2n = _a.p2n, keyList = _a.keyList;
        var _c = _this.props, focusable = _c.focusable, autoFocus = _c.autoFocus, focusedKey = _c.focusedKey;
        var willReceiveProps = false;
        var _d = getCheckedKeys(props, willReceiveProps, k2n, p2n), checkedKeys = _d.checkedKeys, _e = _d.indeterminateKeys, indeterminateKeys = _e === void 0 ? [] : _e;
        _this.state = {
            _k2n: k2n,
            _p2n: p2n,
            _keyList: keyList,
            dataSource: dataSource,
            willReceiveProps: willReceiveProps,
            expandedKeys: getExpandedKeys(props, willReceiveProps, null, k2n, p2n),
            selectedKeys: getSelectedKeys(props, willReceiveProps, k2n, p2n),
            checkedKeys: checkedKeys,
            indeterminateKeys: indeterminateKeys,
        };
        if (focusable) {
            _this.tabbableKey = _this.getFirstAvaliablelChildKey('0');
        }
        _this.state.focusedKey = null;
        if ('focusedKey' in props) {
            _this.state.focusedKey = focusedKey;
        }
        else if (focusable && autoFocus) {
            _this.state.focusedKey = _this.tabbableKey;
        }
        if (focusable) {
            _this.tabbableKey = _this.getFirstAvaliablelChildKey('0');
        }
        bindCtx(_this, [
            'handleExpand',
            'handleSelect',
            'handleCheck',
            'handleBlur',
            'getTreeChildren',
        ]);
        return _this;
    }
    Tree.getDerivedStateFromProps = function (props, state) {
        var _a = getData(props), _b = _a.dataSource, dataSource = _b === void 0 ? [] : _b, k2n = _a.k2n, p2n = _a.p2n, keyList = _a.keyList;
        var st = {};
        if (!state.willReceiveProps) {
            return {
                willReceiveProps: true,
                _k2n: k2n,
                _p2n: p2n,
                _keyList: keyList,
            };
        }
        if ('expandedKeys' in props ||
            (!state.expandedKeys && 'defaultExpandedKeys' in props)) {
            // 最终的展开值，受控于state.expandedKeys
            // 节点数据存在异步操作的情况，后续的defaultExpandedKeys，只要没有不是受控且不存用户操作，依然需要生效，所以添加'defaultExpandedKeys' in props
            st.expandedKeys = getExpandedKeys(props, state.willReceiveProps, state.expandedKeys, k2n, p2n);
        }
        if ('selectedKeys' in props) {
            st.selectedKeys = getSelectedKeys(props, state.willReceiveProps, k2n);
        }
        if ('checkedKeys' in props) {
            var checkedKeys = getCheckedKeys(props, state.willReceiveProps, k2n, p2n).checkedKeys;
            st.checkedKeys = checkedKeys;
        }
        st.indeterminateKeys = props.checkStrictly
            ? (props.checkedKeys && props.checkedKeys.indeterminate) || []
            : getIndeterminateKeys(st.checkedKeys || state.checkedKeys || [], props.checkStrictly, k2n, p2n);
        // 针对异步场景，如果设置 expandAll 则无条件展开所有节点
        if (props.expandAll) {
            st.expandedKeys = getExpandedKeys(props, state.willReceiveProps, state.expandedKeys, k2n, p2n);
        }
        return __assign(__assign({}, st), { dataSource: dataSource, _k2n: k2n, _p2n: p2n });
    };
    Tree.prototype.getTreeChildren = function () {
        return this.treeChildren;
    };
    Tree.prototype.setFocusKey = function () {
        var _a = this.state.selectedKeys, selectedKeys = _a === void 0 ? [] : _a;
        this.setState({
            focusedKey: selectedKeys.length > 0
                ? selectedKeys[0]
                : this.getFirstAvaliablelChildKey('0'),
        });
    };
    Tree.prototype.getAvailableKey = function (pos, prev) {
        var _this = this;
        var ps = Object.keys(this.state._p2n).filter(function (p) {
            return _this.isAvailablePos(pos, p);
        });
        if (ps.length > 1) {
            var index = ps.indexOf(pos);
            var targetIndex = void 0;
            if (prev) {
                targetIndex = index === 0 ? ps.length - 1 : index - 1;
            }
            else {
                targetIndex = index === ps.length - 1 ? 0 : index + 1;
            }
            return this.state._p2n[ps[targetIndex]].key;
        }
        return null;
    };
    Tree.prototype.getFirstAvaliablelChildKey = function (parentPos) {
        var _this = this;
        var pos = Object.keys(this.state._p2n).find(function (p) {
            return _this.isAvailablePos("".concat(parentPos, "-0"), p);
        });
        return pos ? this.state._p2n[pos].key : null;
    };
    Tree.prototype.isAvailablePos = function (refPos, targetPos) {
        var disabled = this.state._p2n[targetPos].disabled;
        return this.isSibling(refPos, targetPos) && !disabled;
    };
    Tree.prototype.isSibling = function (currentPos, targetPos) {
        var currentNums = currentPos.split('-').slice(0, -1);
        var targetNums = targetPos.split('-').slice(0, -1);
        return (currentNums.length === targetNums.length &&
            currentNums.every(function (num, index) {
                return num === targetNums[index];
            }));
    };
    Tree.prototype.getParentKey = function (pos) {
        return this.state._p2n[pos.slice(0, pos.length - 2)].key;
    };
    Tree.prototype.processKey = function (keys, key, add) {
        var _a;
        var index = (_a = keys === null || keys === void 0 ? void 0 : keys.indexOf) === null || _a === void 0 ? void 0 : _a.call(keys, key);
        if (add && index === -1) {
            keys.push(key);
        }
        else if (!add && index > -1) {
            keys.splice(index, 1);
        }
        return keys;
    };
    /* eslint-disable max-statements */
    Tree.prototype.handleItemKeyDown = function (key, item, e) {
        if ([
            KEYCODE.UP,
            KEYCODE.DOWN,
            KEYCODE.RIGHT,
            KEYCODE.LEFT,
            KEYCODE.ENTER,
            KEYCODE.ESC,
            KEYCODE.SPACE,
        ].indexOf(e.keyCode) > -1) {
            e.preventDefault();
            e.stopPropagation();
        }
        var focusedKey = this.state.focusedKey;
        var node = this.state._k2n[key];
        var pos = this.state._k2n[key].pos;
        var level = pos.split('-').length - 1;
        switch (e.keyCode) {
            case KEYCODE.UP: {
                var avaliableKey = this.getAvailableKey(pos, true);
                if (avaliableKey) {
                    focusedKey = avaliableKey;
                }
                break;
            }
            case KEYCODE.DOWN: {
                var avaliableKey = this.getAvailableKey(pos, false);
                if (avaliableKey) {
                    focusedKey = avaliableKey;
                }
                break;
            }
            case KEYCODE.RIGHT: {
                this.handleExpand(true, key, node);
                var avaliableKey = this.getFirstAvaliablelChildKey(pos);
                if (avaliableKey) {
                    focusedKey = avaliableKey;
                }
                break;
            }
            case KEYCODE.LEFT:
            case KEYCODE.ESC: {
                if (level === 1) {
                    var avaliableKey = this.getAvailableKey(pos, true);
                    if (avaliableKey) {
                        focusedKey = avaliableKey;
                    }
                }
                else if (level > 1) {
                    var parentKey = this.getParentKey(pos);
                    this.handleExpand(false, parentKey, node);
                    focusedKey = parentKey;
                }
                break;
            }
            case KEYCODE.ENTER:
            case KEYCODE.SPACE: {
                var checkable = 'checkable' in item.props
                    ? item.props.checkable
                    : this.props.checkable;
                var selectable = 'selectable' in item.props
                    ? item.props.selectable
                    : this.props.selectable;
                if (checkable) {
                    this.handleCheck(!item.props.checked, key, node);
                }
                else if (selectable) {
                    this.handleSelect(!item.props.selected, key, node, e);
                }
                break;
            }
            case KEYCODE.TAB:
                focusedKey = null;
                break;
            default:
                break;
        }
        if (focusedKey !== this.state.focusedKey) {
            if (!('focusedKey' in this.props)) {
                this.setState({
                    focusedKey: focusedKey,
                });
            }
        }
        this.props.onItemKeyDown(focusedKey, item, e);
        this.props.onItemFocus(focusedKey, e);
    };
    Tree.prototype.handleBlur = function (e) {
        this.setState({
            focusedKey: null,
        });
        this.props.onBlur && this.props.onBlur(e);
    };
    Tree.prototype.handleExpand = function (expand, key, node) {
        var _this = this;
        var _a;
        var _b = this.props, onExpand = _b.onExpand, loadData = _b.loadData;
        var expandedKeys = (_a = this.state.expandedKeys) !== null && _a !== void 0 ? _a : [];
        // 增加 expandedKeys 默认[], 为了防止进入子节点归并父节点的逻辑。
        this.processKey(expandedKeys, key, expand);
        var setExpandedState = function () {
            var _a;
            if (!('expandedKeys' in _this.props)) {
                _this.setState({ expandedKeys: expandedKeys });
            }
            // 防止memo浅对比带来的问题
            onExpand((_a = expandedKeys === null || expandedKeys === void 0 ? void 0 : expandedKeys.slice) === null || _a === void 0 ? void 0 : _a.call(expandedKeys), { expanded: expand, node: node });
        };
        if (expand && loadData) {
            return loadData(node).then(setExpandedState);
        }
        else {
            setExpandedState();
        }
    };
    Tree.prototype.handleSelect = function (select, key, node, e) {
        var _a = this.props, multiple = _a.multiple, onSelect = _a.onSelect;
        var selectedKeys = __spreadArray([], this.state.selectedKeys, true);
        if (multiple) {
            this.processKey(selectedKeys, key, select);
        }
        else {
            selectedKeys = select ? [key] : [];
        }
        if (!('selectedKeys' in this.props)) {
            this.setState({ selectedKeys: selectedKeys });
        }
        onSelect(selectedKeys, {
            selectedNodes: this.getNodes(selectedKeys),
            node: node,
            selected: select,
            event: e,
        });
    };
    // eslint-disable-next-line max-statements
    Tree.prototype.handleCheck = function (check, key, node) {
        var _this = this;
        var _a = this.props, checkStrictly = _a.checkStrictly, checkedStrategy = _a.checkedStrategy, onCheck = _a.onCheck;
        var _b = this.state, _k2n = _b._k2n, _p2n = _b._p2n;
        var checkedKeys = __spreadArray([], this.state.checkedKeys, true);
        if (checkStrictly) {
            this.processKey(checkedKeys, key, check);
            var newCheckedKeys_1 = isPlainObject(this.props.checkedKeys)
                ? {
                    checked: checkedKeys,
                    indeterminate: this.state.indeterminateKeys,
                }
                : checkedKeys;
            onCheck(newCheckedKeys_1, {
                checkedNodes: this.getNodes(checkedKeys),
                checkedNodesPositions: checkedKeys
                    .map(function (_key) {
                    if (!_k2n[_key])
                        return null;
                    return { node: _k2n[_key].node, pos: _k2n[_key].pos };
                })
                    .filter(function (v) { return !!v; }),
                node: node,
                indeterminateKeys: this.state.indeterminateKeys,
                checked: check,
                key: key,
            });
            return;
        }
        var pos = _k2n[key].pos;
        forEachEnableNode(_k2n[key], function (_node) {
            if (_node.checkable === false)
                return;
            _this.processKey(checkedKeys, _node.key, check);
        });
        var ps = Object.keys(_p2n);
        var currentPos = pos;
        var nums = pos.split('-');
        for (var i = nums.length; i > 2; i--) {
            var parentCheck = true;
            var parentPos = nums.slice(0, i - 1).join('-');
            if (_p2n[parentPos].disabled ||
                _p2n[parentPos].checkboxDisabled ||
                _p2n[parentPos].checkable === false) {
                currentPos = parentPos;
                continue;
            }
            var parentKey = _p2n[parentPos].key;
            var parentChecked = checkedKeys.indexOf(parentKey) > -1;
            if (!check && !parentChecked) {
                break;
            }
            for (var j = 0; j < ps.length; j++) {
                var p = ps[j];
                var pnode = _p2n[p];
                if (isSiblingOrSelf(currentPos, p) &&
                    !pnode.disabled &&
                    !pnode.checkboxDisabled) {
                    var k = pnode.key;
                    if (pnode.checkable === false) {
                        // eslint-disable-next-line max-depth
                        if (!pnode.children || pnode.children.length === 0)
                            continue;
                        // eslint-disable-next-line max-depth
                        for (var m = 0; m < pnode.children.length; m++) {
                            if (!pnode.children.every(function (child) {
                                return isNodeChecked(child, checkedKeys);
                            })) {
                                parentCheck = false;
                                break;
                            }
                        }
                    }
                    else if (checkedKeys.indexOf(k) === -1) {
                        parentCheck = false;
                    }
                    if (!parentCheck)
                        break;
                }
            }
            this.processKey(checkedKeys, parentKey, parentCheck);
            currentPos = parentPos;
        }
        var indeterminateKeys = getIndeterminateKeys(checkedKeys, checkStrictly, _k2n, _p2n);
        if (!('checkedKeys' in this.props)) {
            this.setState({
                checkedKeys: checkedKeys,
                indeterminateKeys: indeterminateKeys,
            });
        }
        var newCheckedKeys;
        switch (checkedStrategy) {
            case 'parent':
                newCheckedKeys = filterChildKey(checkedKeys, _k2n, _p2n);
                break;
            case 'child':
                newCheckedKeys = filterParentKey(checkedKeys, _k2n, _p2n);
                break;
            default:
                newCheckedKeys = checkedKeys;
                break;
        }
        onCheck(newCheckedKeys, {
            checkedNodes: this.getNodes(newCheckedKeys),
            checkedNodesPositions: newCheckedKeys
                .map(function (_key) {
                if (!_k2n[_key])
                    return null;
                return { node: _k2n[_key].node, pos: _k2n[_key].pos };
            })
                .filter(function (v) { return !!v; }),
            node: node,
            indeterminateKeys: indeterminateKeys,
            checked: check,
            key: key,
        });
    };
    Tree.prototype.getNodeProps = function (key) {
        var _a;
        var prefix = this.props.prefix;
        var _b = this.state, expandedKeys = _b.expandedKeys, selectedKeys = _b.selectedKeys, checkedKeys = _b.checkedKeys, dragOverNodeKey = _b.dragOverNodeKey, _c = _b._k2n, _k2n = _c === void 0 ? {} : _c, indeterminateKeys = _b.indeterminateKeys;
        var _d = (_a = _k2n[key]) !== null && _a !== void 0 ? _a : {}, pos = _d.pos, isLeaf = _d.isLeaf, level = _d.level;
        var nodeProps = {
            prefix: prefix,
            root: this,
            eventKey: key,
            pos: pos,
            isLeaf: isLeaf,
            level: level,
            expanded: (expandedKeys === null || expandedKeys === void 0 ? void 0 : expandedKeys.indexOf(key)) > -1,
            selected: selectedKeys.indexOf(key) > -1,
            checked: checkedKeys.indexOf(key) > -1,
            indeterminate: indeterminateKeys.indexOf(key) > -1,
            dragOver: dragOverNodeKey === key && this.dropPosition === 0,
            dragOverGapTop: dragOverNodeKey === key && this.dropPosition === -1,
            dragOverGapBottom: dragOverNodeKey === key && this.dropPosition === 1,
        };
        if (_k2n[key] && 'selectable' in _k2n[key]) {
            nodeProps.selectable = _k2n[key].selectable;
        }
        return nodeProps;
    };
    Tree.prototype.getNodes = function (keys) {
        var _this = this;
        return keys
            .map(function (key) { return _this.state._k2n[key] && _this.state._k2n[key].node; })
            .filter(function (v) { return !!v; });
    };
    Tree.prototype.handleDragStart = function (e, node) {
        var _this = this;
        var dragNodeKey = node.props.eventKey;
        this.dragNode = node;
        this.dragNodesKeys = Object.keys(this.state._k2n).filter(function (k) {
            return isDescendantOrSelf(_this.state._k2n[dragNodeKey].pos, _this.state._k2n[k].pos);
        });
        this.setState(function (prevState) {
            var expandedKeys = _this.processKey(__spreadArray([], (prevState.expandedKeys || []), true), dragNodeKey, false);
            _this.props.onDragStart({
                event: e,
                node: node,
                expandedKeys: expandedKeys,
            });
            return { expandedKeys: expandedKeys };
        });
    };
    Tree.prototype.handleDragEnter = function (e, node) {
        var _this = this;
        var dragOverNodeKey = node.props.eventKey;
        this.dropPosition = this.getDropPosition(e, node);
        if (this.dragNode &&
            this.dragNode.props.eventKey === dragOverNodeKey &&
            this.dropPosition === 0) {
            this.setState({
                dragOverNodeKey: null,
            });
            return;
        }
        this.setState(function (prevState) {
            var expandedKeys = _this.processKey(__spreadArray([], (prevState.expandedKeys || []), true), dragOverNodeKey, true);
            _this.props.onDragEnter({
                event: e,
                node: node,
                expandedKeys: expandedKeys,
            });
            return {
                dragOverNodeKey: dragOverNodeKey,
                expandedKeys: expandedKeys,
            };
        });
    };
    Tree.prototype.getDropPosition = function (e, node) {
        var labelWrapperNode = node.labelWrapperEl;
        var offsetTop = getOffset(labelWrapperNode).top;
        var offsetHeight = labelWrapperNode.offsetHeight;
        var pageY = e.pageY;
        var gapHeight = 2;
        if (pageY > offsetTop + offsetHeight - gapHeight) {
            return 1;
        }
        if (pageY < offsetTop + gapHeight) {
            return -1;
        }
        return 0;
    };
    Tree.prototype.handleDragOver = function (e, node) {
        var dragOverNodeKey = node.props.eventKey;
        if (this.state.dragOverNodeKey !== dragOverNodeKey) {
            this.setState({
                dragOverNodeKey: dragOverNodeKey,
            });
        }
        this.props.onDragOver({ event: e, node: node });
    };
    Tree.prototype.handleDragLeave = function (e, node) {
        var eventKey = node.props.eventKey;
        var _keyList = this.state._keyList;
        var firstKey = _keyList[0];
        var lastKey = _keyList[_keyList.length - 1];
        // 只针对树的边界节点（第一个和最后一个）做处理
        if (eventKey === firstKey || eventKey === lastKey) {
            this.setState({
                dragOverNodeKey: null,
            });
        }
        this.props.onDragLeave({ event: e, node: node });
    };
    Tree.prototype.handleDragEnd = function (e, node) {
        this.setState({
            dragOverNodeKey: null,
        });
        this.props.onDragEnd({ event: e, node: node });
    };
    Tree.prototype.handleDrop = function (e, node) {
        if (this.dragNode &&
            isDescendantOrSelf(this.state._k2n[this.dragNode.props.eventKey].pos, this.state._k2n[node.props.eventKey].pos)) {
            return;
        }
        this.setState({
            dragOverNodeKey: null,
        });
        var params = this.generateDropParams(node);
        this.props.onDrop(__assign({ event: e }, params));
    };
    Tree.prototype.canDrop = function (node) {
        var params = this.generateDropParams(node);
        return this.props.canDrop(params);
    };
    Tree.prototype.generateDropParams = function (node) {
        return {
            dragNode: this.dragNode,
            dragNodesKeys: __spreadArray([], this.dragNodesKeys, true),
            node: node,
            dropPosition: this.dropPosition,
        };
    };
    Tree.prototype.filterTreeNode = function (node) {
        return this.props.filterTreeNode.call(this, node);
    };
    Tree.prototype.shouldNodeShow = function (nodeData) {
        var _a = this.state, expandedKeys = _a.expandedKeys, _p2n = _a._p2n;
        return (!(nodeData.style && nodeData.style.display === 'none') &&
            getAllDescendantKeys(nodeData, _p2n).every(function (k) {
                return expandedKeys === null || expandedKeys === void 0 ? void 0 : expandedKeys.includes(k);
            }));
    };
    Tree.prototype.renderTreeNode = function (props, childNodes) {
        var _a = this.props, rtl = _a.rtl, labelRender = _a.labelRender;
        var key = props.key;
        var nodeProps = __assign(__assign({ _key: key }, props), this.getNodeProps(key));
        if (labelRender) {
            nodeProps.label = labelRender(props);
        }
        return (React.createElement(TreeNode, __assign({ rtl: rtl, key: key }, nodeProps), childNodes));
    };
    Tree.prototype.renderNodeList = function (dataSource) {
        var _this = this;
        var nodeList = [];
        var _k2n = this.state._k2n;
        var drill = function (list) {
            list.forEach(function (item) {
                // 为了兼容之前的实现 保留非法节点
                if (item.illegalFlag) {
                    nodeList.push(item);
                    return;
                }
                var children = item.children, nodeProps = __rest(item, ["children"]);
                if (!_this.shouldNodeShow(item)) {
                    return;
                }
                nodeList.push(nodeProps);
                children && children.length && drill(children);
            });
        };
        drill(dataSource);
        var nodes = nodeList.map(function (nodeProps, index) {
            // 为了兼容之前的实现 保留非法节点
            if (nodeProps.illegalFlag) {
                return nodeProps.node;
            }
            // aria props
            nodeProps.size = nodeList.length;
            nodeProps.posinset = index + 1;
            _k2n[nodeProps.key].node = _this.renderTreeNode(nodeProps);
            return _k2n[nodeProps.key].node;
        });
        this.treeChildren = nodes;
        return nodes;
    };
    Tree.prototype.renderWithCustomChildNodes = function (dataSource) {
        var _this = this;
        var renderChildNodes = this.props.renderChildNodes;
        var _k2n = this.state._k2n;
        var drill = function (list) {
            return list.map(function (_a, index) {
                var children = _a.children, nodeProps = __rest(_a, ["children"]);
                var childNodes;
                if (children && children.length) {
                    childNodes = renderChildNodes(drill(children));
                }
                // aria props
                nodeProps.size = list.length;
                nodeProps.posinset = index + 1;
                var renderNode = _this.renderTreeNode(nodeProps, childNodes);
                _k2n[nodeProps.key].node = renderNode;
                return renderNode;
            });
        };
        return drill(dataSource);
    };
    Tree.prototype.renderByDataSource = function (dataSource) {
        var _this = this;
        var drill = function (data, prefix) {
            if (prefix === void 0) { prefix = '0'; }
            return data.map(function (item, index) {
                // 为了兼容之前的实现 保留非法节点
                if (item.illegalFlag) {
                    return item.node;
                }
                var pos = "".concat(prefix, "-").concat(index);
                var _a = item.key, key = _a === void 0 ? pos : _a, children = item.children, others = __rest(item, ["key", "children"]);
                var props = __assign(__assign(__assign({ size: data.length }, others), _this.getNodeProps("".concat(key))), { _key: key, key: key });
                if (children && children.length) {
                    props.children = drill(children, pos);
                }
                var node = _this.renderTreeNode(props, props.children);
                // eslint-disable-next-line
                _this.state._k2n[key].node = node;
                return node;
            });
        };
        return drill(dataSource);
    };
    Tree.prototype.renderByChildren = function () {
        var _this = this;
        var rtl = this.props.rtl;
        var _k2n = this.state._k2n;
        var loop = function (children, prefix) {
            if (prefix === void 0) { prefix = '0'; }
            return Children.map(children, function (child, index) {
                if (!React.isValidElement(child)) {
                    return;
                }
                var pos = "".concat(prefix, "-").concat(index);
                var key = child.key || pos;
                var props = _this.getNodeProps("".concat(key));
                if (child.props.children) {
                    props.children = loop(child.props.children, pos);
                }
                props._key = key;
                props.rtl = rtl;
                props.size = Children.count(children);
                var node = cloneElement(child, props);
                _k2n[key].node = node;
                return node;
            });
        };
        var c = loop(this.props.children);
        return c;
    };
    Tree.prototype.renderContent = function () {
        var _this = this;
        var _a = this.props, prefix = _a.prefix, rtl = _a.rtl, className = _a.className, showLine = _a.showLine, isNodeBlock = _a.isNodeBlock, isLabelBlock = _a.isLabelBlock, multiple = _a.multiple, useVirtual = _a.useVirtual, renderChildNodes = _a.renderChildNodes;
        var dataSource = this.state.dataSource;
        var _b = pickOthers(Object.keys(Tree.propTypes), this.props), style = _b.style, others = __rest(_b, ["style"]);
        if (rtl) {
            others.dir = 'rtl';
        }
        var treeRender = function (items, ref, shouldSaveTreeChildren) {
            var _a;
            if (shouldSaveTreeChildren === void 0) { shouldSaveTreeChildren = true; }
            // 用于全选拿到实际的节点 先这样兼容
            if (shouldSaveTreeChildren) {
                _this.treeChildren = items;
            }
            return (React.createElement("ul", __assign({ role: "tree", ref: ref, "aria-multiselectable": multiple, onBlur: _this.handleBlur, className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(prefix, "tree"), (_a = {},
                    _a["".concat(prefix, "label-block")] = isLabelBlock,
                    _a["".concat(prefix, "node-block")] = isNodeBlock,
                    _a["".concat(prefix, "node-indent")] = !isNodeBlock,
                    _a["".concat(prefix, "show-line")] = !isNodeBlock && showLine,
                    _a)), style: useVirtual ? null : style }, others), items));
        };
        var virtualTreeRender = function (_dataSource) {
            return (React.createElement("div", { className: "".concat(prefix, "virtual-tree-container"), style: style },
                React.createElement(VirtualList, { itemsRenderer: function (items, ref) { return treeRender(items, ref, false); } }, _this.renderNodeList(_dataSource))));
        };
        if (useVirtual) {
            return virtualTreeRender(dataSource);
        }
        if (renderChildNodes) {
            return treeRender(this.renderWithCustomChildNodes(dataSource));
        }
        if (!this.props.dataSource) {
            return treeRender(this.renderByChildren());
        }
        return treeRender(this.renderByDataSource(dataSource));
    };
    Tree.prototype.render = function () {
        var _a = this.props, header = _a.header, footer = _a.footer, prefix = _a.prefix;
        return (React.createElement(React.Fragment, null,
            header && (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(prefix, "tree-header")) }, header)),
            this.renderContent(),
            footer && (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(prefix, "tree-footer")) }, footer))));
    };
    Tree.propTypes = {
        prefix: PropTypes.string,
        rtl: PropTypes.bool,
        pure: PropTypes.bool,
        className: PropTypes.string,
        /**
         * 树节点
         */
        children: PropTypes.node,
        /**
         * 数据源，该属性优先级高于 children
         */
        dataSource: PropTypes.array,
        /**
         * 是否显示树的线
         */
        showLine: PropTypes.bool,
        /**
         * 是否支持选中节点
         */
        selectable: PropTypes.bool,
        /**
         * （用于受控）当前选中节点 key 的数组
         */
        selectedKeys: PropTypes.arrayOf(PropTypes.string),
        /**
         * （用于非受控）默认选中节点 key 的数组
         */
        defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
        /**
         * 选中或取消选中节点时触发的回调函数
         * @param {Array} selectedKeys 选中节点key的数组
         * @param {Object} extra 额外参数
         * @param {Array} extra.selectedNodes 选中节点的数组
         * @param {Object} extra.node 当前操作的节点
         * @param {Boolean} extra.selected 当前操作是否是选中
         */
        onSelect: PropTypes.func,
        /**
         * 是否支持多选
         */
        multiple: PropTypes.bool,
        /**
         * 是否支持勾选节点的复选框
         */
        checkable: PropTypes.bool,
        /**
         * （用于受控）当前勾选复选框节点 key 的数组或 `{checked: Array, indeterminate: Array}` 的对象
         */
        checkedKeys: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.object,
        ]),
        /**
         * （用于非受控）默认勾选复选框节点 key 的数组
         */
        defaultCheckedKeys: PropTypes.arrayOf(PropTypes.string),
        /**
         * 勾选节点复选框是否完全受控（父子节点选中状态不再关联）
         */
        checkStrictly: PropTypes.bool,
        /**
         * 定义选中时回填的方式
         * @enumdesc 返回所有选中的节点, 父子节点都选中时只返回父节点, 父子节点都选中时只返回子节点
         */
        checkedStrategy: PropTypes.oneOf(['all', 'parent', 'child']),
        /**
         * 勾选或取消勾选复选框时触发的回调函数
         * @param {Array} checkedKeys 勾选复选框节点key的数组
         * @param {Object} extra 额外参数
         * @param {Array} extra.checkedNodes 勾选复选框节点的数组
         * @param {Array} extra.checkedNodesPositions 包含有勾选复选框节点和其位置的对象的数组
         * @param {Array} extra.indeterminateKeys 半选复选框节点 key 的数组
         * @param {Object} extra.node 当前操作的节点
         * @param {Boolean} extra.checked 当前操作是否是勾选
         */
        onCheck: PropTypes.func,
        /**
         * （用于受控）当前展开的节点 key 的数组
         */
        expandedKeys: PropTypes.arrayOf(PropTypes.string),
        /**
         * （用于非受控）默认展开的节点 key 的数组
         */
        defaultExpandedKeys: PropTypes.arrayOf(PropTypes.string),
        /**
         * 是否默认展开所有节点
         */
        defaultExpandAll: PropTypes.bool,
        /**
         * 是否自动展开父节点，建议受控时设置为false
         */
        autoExpandParent: PropTypes.bool,
        /**
         * 展开或收起节点时触发的回调函数
         * @param {Array} expandedKeys 展开的节点key的数组
         * @param {Object} extra 额外参数
         * @param {Object} extra.node 当前操作的节点
         * @param {Boolean} extra.expanded 当前操作是否是展开
         */
        onExpand: PropTypes.func,
        /**
         * 是否支持编辑节点内容
         */
        editable: PropTypes.bool,
        /**
         * 编辑节点内容完成时触发的回调函数
         * @param {String} key 编辑节点的 key
         * @param {String} label 编辑节点完成时节点的文本
         * @param {Object} node 当前编辑的节点
         */
        onEditFinish: PropTypes.func,
        /**
         * 是否支持拖拽节点
         */
        draggable: PropTypes.bool,
        /**
         * 开始拖拽节点时触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 拖拽的节点
         */
        onDragStart: PropTypes.func,
        /**
         * 拖拽节点进入目标节点时触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 目标节点
         * @param {Array} info.expandedKeys 当前展开的节点key的数组
         */
        onDragEnter: PropTypes.func,
        /**
         * 拖拽节点在目标节点上移动的时候触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 目标节点
         */
        onDragOver: PropTypes.func,
        /**
         * 拖拽节点离开目标节点时触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 目标节点
         */
        onDragLeave: PropTypes.func,
        /**
         * 拖拽节点拖拽结束时触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 目标节点
         */
        onDragEnd: PropTypes.func,
        /**
         * 拖拽节点放入目标节点内或前后触发的回调函数
         * @param {Object} info 拖拽信息
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 目标节点
         * @param {Object} info.dragNode 拖拽的节点
         * @param {Array} info.dragNodesKeys 拖拽的节点和其子节点 key 的数组
         * @param {Number} info.dropPosition 放置位置，-1代表当前节点前，0代表当前节点里，1代表当前节点后
         */
        onDrop: PropTypes.func,
        /**
         * 节点是否可被作为拖拽的目标节点
         * @param {Object} info 拖拽信息
         * @param {Object} info.node 目标节点
         * @param {Object} info.dragNode 拖拽的节点
         * @param {Array} info.dragNodesKeys 拖拽的节点和其子节点 key 的数组
         * @param {Number} info.dropPosition 放置位置，-1代表当前节点前，0代表当前节点里，1代表当前节点后
         * @return {Boolean} 是否可以被当作目标节点
         */
        canDrop: PropTypes.func,
        /**
         * 异步加载数据的函数
         * @param {Object} node 被点击展开的节点
         */
        loadData: PropTypes.func,
        /**
         * 按需筛选高亮节点
         * @param {Object} node 待筛选的节点
         * @return {Boolean} 是否被筛选中
         */
        filterTreeNode: PropTypes.func,
        /**
         * 右键点击节点时触发的回调函数
         * @param {Object} info 信息对象
         * @param {Object} info.event 事件对象
         * @param {Object} info.node 点击的节点
         */
        onRightClick: PropTypes.func,
        /**
         * 设置节点是否占满剩余空间，一般用于统一在各节点右侧添加元素(借助 flex 实现，暂时只支持 ie10+)
         */
        isLabelBlock: PropTypes.bool,
        /**
         * 设置节点是否占满一行
         */
        isNodeBlock: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        /**
         * 设置是否仅叶子节点可选中
         */
        isOnlyLeafNodeSelectable: PropTypes.bool,
        /**
         * 是否开启展开收起动画
         */
        animation: PropTypes.bool,
        /**
         * 当前获得焦点的子菜单或菜单项 key 值
         */
        focusedKey: PropTypes.string,
        /**
         * 渲染子节点
         * @param {Array<ReactNode>} nodes 所有的子节点
         * @return {ReactNode} 返回节点
         */
        renderChildNodes: PropTypes.func,
        focusable: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onItemFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onItemKeyDown: PropTypes.func,
        /**
         * 自定义渲染单个子节点
         * @param {Object} node 节点数据
         * @return {ReactNode} 返回节点
         */
        labelRender: PropTypes.func,
        /**
         * 是否开启虚拟滚动
         */
        useVirtual: PropTypes.bool,
        /**
         * 是否是不可变数据
         */
        immutable: PropTypes.bool,
        /**
         * 自定义头部
         */
        header: PropTypes.node,
        /**
         * 自定义尾部
         */
        footer: PropTypes.node,
    };
    Tree.defaultProps = {
        prefix: 'cn-next-',
        rtl: false,
        pure: false,
        showLine: false,
        selectable: true,
        editable: false,
        multiple: false,
        checkable: false,
        checkStrictly: false,
        checkedStrategy: 'all',
        draggable: false,
        autoExpandParent: true,
        defaultExpandAll: false,
        defaultExpandedKeys: [],
        defaultCheckedKeys: [],
        defaultSelectedKeys: [],
        onExpand: noop,
        onCheck: noop,
        onSelect: noop,
        onDragStart: noop,
        onDragEnter: noop,
        onDragOver: noop,
        onDragLeave: noop,
        onDragEnd: noop,
        onDrop: noop,
        canDrop: function () { return true; },
        onEditFinish: noop,
        onRightClick: noop,
        isLabelBlock: false,
        isNodeBlock: false,
        isOnlyLeafNodeSelectable: false,
        animation: true,
        focusable: true,
        autoFocus: false,
        onItemFocus: noop,
        onItemKeyDown: noop,
        useVirtual: false,
        immutable: false,
    };
    return Tree;
}(Component));
export default polyfill(Tree);
