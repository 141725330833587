import { __assign } from "tslib";
import './cn-array-sub-area-card.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import { formilyReact } from '@/form/formily';
import { CnCardSubAreaCard } from '@/components/cn-card';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnFormGrid } from '@/components/cn-form-grid';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';
import { CnBox } from '@/components/cn-box';
import { CnFormArrayBase, mixinCnFormArrayBase, } from '@/form/cn-form-array-base';
import { isAdditionComponent, isOperationComponent } from '../utils';
var useField = formilyReact.useField, observer = formilyReact.observer, useFieldSchema = formilyReact.useFieldSchema, RecursionField = formilyReact.RecursionField;
var CnArraySubAreaCardView = observer(function (props) {
    var _a;
    var _b = props.allowRemove, allowRemove = _b === void 0 ? true : _b, _c = props.cols, cols = _c === void 0 ? 1 : _c;
    var field = useField();
    var schema = useFieldSchema();
    var dataSource = Array.isArray(field.value) ? field.value : [];
    var readOnly = (_a = field === null || field === void 0 ? void 0 : field.form) === null || _a === void 0 ? void 0 : _a.readOnly;
    if (typeof props.readOnly === 'boolean') {
        readOnly = props.readOnly;
    }
    return (React.createElement(CnFormArrayBase, null,
        React.createElement(CnFormGrid, __assign({ cols: cols }, pick(props, [
            'gridItemStyle',
            'columnGap',
            'rowGap',
            'slot',
            'area',
            'responsive',
            'onColumnChange',
        ])),
            dataSource.map(function (item, index) {
                var itemsSchema = Array.isArray(schema.items)
                    ? schema.items[index] || schema.items[0]
                    : schema.items;
                var itemTitle = props.title || field.title;
                if (isFunction(props === null || props === void 0 ? void 0 : props.title)) {
                    itemTitle = props.title(item, index);
                }
                return (React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { return item; } },
                    React.createElement(CnCardSubAreaCard, { title: itemTitle, action: React.createElement(CnBox, { direction: "row", spacing: 8, align: "center" },
                            typeof props.titleSlot === 'function'
                                ? props.titleSlot(item, index)
                                : null,
                            !readOnly && allowRemove && (React.createElement(CnButton, { text: true, className: "cn-ui-formily-array-sub-area-card-action-remove", onClick: function (e) {
                                    var _a;
                                    e.stopPropagation();
                                    (_a = field === null || field === void 0 ? void 0 : field.remove) === null || _a === void 0 ? void 0 : _a.call(field, index);
                                } },
                                React.createElement(CnIcon, { type: "delete" }),
                                $i18n.get({
                                    id: '31255239516622848.CNTM',
                                    dm: '删除',
                                    ns: 'CnForm',
                                }))),
                            React.createElement(RecursionField, { schema: itemsSchema, name: index, filterProperties: function (contentSchema) {
                                    return isOperationComponent(contentSchema);
                                }, onlyRenderProperties: true })), style: props.style, className: classNames('cn-ui-formily-array-sub-area-card-item', props.className), onClick: function (event) {
                            var _a;
                            (_a = props.onItemClick) === null || _a === void 0 ? void 0 : _a.call(props, event, item, index);
                        }, onClickCapture: function (event) {
                            var _a;
                            (_a = props.onItemClickCapture) === null || _a === void 0 ? void 0 : _a.call(props, event, item, index);
                        } },
                        React.createElement(RecursionField, { schema: itemsSchema, name: index, filterProperties: function (fieldSchema) {
                                return !isOperationComponent(fieldSchema);
                            } }))));
            }),
            React.createElement("div", null, schema.reduceProperties(function (addition, additionSchema, key) {
                if (isAdditionComponent(additionSchema)) {
                    return React.createElement(RecursionField, { schema: additionSchema, name: key });
                }
                return addition;
            }, null)))));
});
export var CnArraySubAreaCard = mixinCnFormArrayBase(CnArraySubAreaCardView);
CnArraySubAreaCard.displayName = 'CnArraySubAreaCard';
