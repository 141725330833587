import { __assign } from "tslib";
import './index.scss';
import React, { Children, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import compact from 'lodash/compact';
import { useLatest } from 'ahooks';
import { getComponentName } from '@cainiaofe/cn-ui-common';
import { useFormLayout } from '@/form/cn-form-layout/hooks';
import { calcCols } from './utils/calc-cols';
import { useFormResponsive } from './hooks/use-form-responsive';
import { useFormGridStyle } from './hooks/use-form-grid-style';
import { getGridItemStyle } from './utils/get-grid-item-style';
export var CnFormGrid = function (props) {
    var gridItemStyle = props.gridItemStyle, columnGap = props.columnGap, rowGap = props.rowGap, slot = props.slot, area = props.area, className = props.className, children = props.children;
    var layoutMode = useFormLayout().layoutMode;
    var _a = useState(), responsiveCols = _a[0], setResponsiveCols = _a[1];
    var responsive = useFormResponsive({
        cols: props.cols,
        responsive: props.responsive,
    });
    var cols = responsive ? responsiveCols : props.cols;
    var gridRef = useRef();
    var resizeObserverRef = useRef();
    var onColumnChange = useLatest(props.onColumnChange);
    var style = useFormGridStyle({
        cols: cols,
        style: props.style,
        rowGap: rowGap,
        columnGap: columnGap,
    });
    useEffect(function () {
        if (!responsive)
            return;
        resizeObserverRef.current = new ResizeObserver(function (entries) {
            var _loop_1 = function (entry) {
                var targetCols = calcCols({ width: entry.target.clientWidth });
                setResponsiveCols(function (prev) {
                    var _a;
                    if (targetCols !== prev) {
                        (_a = onColumnChange.current) === null || _a === void 0 ? void 0 : _a.call(onColumnChange, targetCols);
                    }
                    return targetCols;
                });
            };
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                _loop_1(entry);
            }
        });
        resizeObserverRef.current.observe(gridRef.current);
        return function () {
            resizeObserverRef.current.disconnect();
        };
    }, [responsive, onColumnChange]);
    return (React.createElement("div", { "data-name": "CnFormGrid", style: style, ref: gridRef, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-grid', 'cn-ui-form-grid', "cn-ui-form-grid--cols-".concat(cols), className, {
            responsive: responsive,
        }) },
        Children.map(compact(Children.toArray(children)), function (item, index) {
            var itemStyle = getGridItemStyle({
                cols: cols,
                layoutMode: layoutMode,
                gridItemStyle: gridItemStyle,
                area: area,
                index: index,
                item: item,
            });
            if (getComponentName(item) === 'CnFormField') {
                var decoratorProps = ((item === null || item === void 0 ? void 0 : item.props) || {}).decoratorProps;
                return React.cloneElement(item, {
                    // 样式需要挂载在外层装饰器上
                    decoratorProps: __assign(__assign({}, decoratorProps), { style: __assign(__assign({}, itemStyle), decoratorProps === null || decoratorProps === void 0 ? void 0 : decoratorProps.style) }),
                });
            }
            return (React.createElement("div", { className: "cn-form-grid-item", style: itemStyle }, item));
        }),
        slot));
};
CnFormGrid.displayName = 'CnFormGrid';
