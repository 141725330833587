import { __assign, __rest } from "tslib";
import './cn-checkbox.scss';
import * as React from 'react';
import { CnCheckboxReadonly } from './cn-checkbox-readonly';
import { Checkbox as NextCheckbox } from '@fusion/lib';
import omit from 'lodash/omit';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnCheckbox = React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
    return withNativeProps(props, React.createElement(NextCheckbox, __assign({ "data-name": "CnCheckbox", ref: ref, isPreview: readOnly, renderPreview: function (val) { return React.createElement(CnCheckboxReadonly, { value: val, readOnlyProps: readOnlyProps }); } }, omit(otherProps, ['$i18n']))));
});
CnCheckbox.displayName = 'CnCheckbox';
CnCheckbox.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
