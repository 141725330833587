import { __assign } from "tslib";
import './cn-progress.scss';
import * as React from 'react';
import NextProgress from '@fusion/lib/progress';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnProgress = React.forwardRef(function (props, ref) {
    return withNativeProps(props, React.createElement(NextProgress, __assign({ "data-testid": "CnProgress", "data-name": "CnProgress" }, props, { ref: ref })));
});
CnProgress.displayName = 'CnProgress';
/**
 * @deprecated  请使用 CnProgress 替换
 */
export var Progress = NextProgress;
