import { getConeArmsTrace, extractTextFromReactNode } from "../../../utils";
import { useEffect } from 'react';
/**
 * 根据CnResult组件的 type 和 subTitle 进行 arms logPageText 上报。
 *
 * @param {Object} props - 包含 type 和 subTitle 的属性对象。
 * @param {string} [props.type] - 信息的类型。
 * @param {React.ReactNode} [props.subTitle] - 包含文本的子标题节点。
 */
var cnResultShowToUserReport = function (_a) {
    var type = _a.type, subTitle = _a.subTitle;
    try {
        if (subTitle) {
            var message = extractTextFromReactNode(subTitle);
            return getConeArmsTrace().logPageText({
                componentType: 'CnResult',
                feedbackType: type,
                message: message,
            });
        }
    }
    catch (error) {
        console.error('Error logging message:', error);
    }
};
/**
 * 自定义 useArmsContentReportCnResult Hook，当 props 变化时触发 arms logPageText 上报。
 *
 * @param {Object} props - 包含 type 和 subTitle 的属性对象。
 * @param {string} [props.type] - 信息的类型。
 * @param {React.ReactNode} [props.subTitle] - 包含文本的子标题节点。
 */
export function useArmsContentReportCnResult(props) {
    var type = props.type, subTitle = props.subTitle;
    useEffect(function () {
        cnResultShowToUserReport({ type: type, subTitle: subTitle });
    }, [type, subTitle]);
}
